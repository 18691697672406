import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Headline } from '../Headline';

const ViewContainer = ({ children, id, title, subtitle }) => {
  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={12}>
          <Headline
            id={`h1-${title.toLowerCase().split(' ').join('-')}${id?.length ? `-${id}` : ''}`}
            title={title}
            subtitle={subtitle}
          />
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

ViewContainer.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default ViewContainer;
