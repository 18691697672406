import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core';

const StyledLegalAgreementContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: '85%',
  overflow: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export { StyledLegalAgreementContainer };
