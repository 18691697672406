import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Modal from '@material-ui/core/Modal';

import { ScreenSmith } from '../ScreenSmith';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { AORadio } from '../AORadio';

const FindProductToQuoteNAICSCodeDialog = ({
  codeOptions,
  handleCancelOnClick,
  naicsCodeText,
  handleMapCodeOnClick,
  selectedRadio,
  setSelectedRadio,
}) => {
  const { UiDefinitionsData } = useScreenDefinition(`FindProductToQuoteNAICSCodeDialog`);

  useEffect(() => {
    if (!codeOptions.length) {
      setSelectedRadio('');
    }
  }, [codeOptions]);

  const RadioButtonRenderer = () => {
    return codeOptions.map((option) => {
      const keyId = JSON.stringify(option);
      return <AORadio key={keyId} id={keyId} value={option.id} label={option.title} />;
    });
  };

  const LocalComponents = { RadioButtonRenderer };

  const localStateMap = {
    naicsCodeText,
    selectedRadio,
    mapCodeButtonDisabled: selectedRadio === '',
  };

  const callBackFunctions = {
    handleCancelOnClick,
    handleRadioOnChange: (e) => setSelectedRadio(e.target.value),
    handleMapCodeOnClick: () => {
      handleMapCodeOnClick();
      handleCancelOnClick();
    },
  };

  return (
    <Modal
      open={codeOptions?.length}
      onClose={() => handleCancelOnClick()}
      aria-labelledby="find-product-to-quote-naics-code-dialog-title"
      aria-describedby="find-product-to-quote-naics-code-dialog-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={LocalComponents}
        />
      )}
    </Modal>
  );
};

FindProductToQuoteNAICSCodeDialog.propTypes = {
  codeOptions: PropTypes.array,
  handleCancelOnClick: PropTypes.func,
  handleCodeOnChange: PropTypes.func,
  handleMapCodeOnClick: PropTypes.func,
  naicsCodeText: PropTypes.string,
  selectedRadio: PropTypes.string,
  setSelectedRadio: PropTypes.func,
};

export default FindProductToQuoteNAICSCodeDialog;
