import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useAxios from 'axios-hooks';
import cuid from 'cuid';
import moment from 'moment';
import { Button, Typography } from '@material-ui/core';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import { PLSWipSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { AgentLookupModal } from '../AgentLookupModal';
import { dataGridDateFormatter } from '../../helpers';

import {
  GET_CL_WIP_PRODUCT_LINES_URL,
  GET_TRANSACTIONS_URL,
  GET_COMMERCIAL_LINES,
} from '../../constants/api';

import { API_CLS_WIP_BASE_URL } from './CommercialLinesServices.api';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';

const CLSWip = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('CLSWorkInProgressPanel');
  const [isWipRedirectErrorVisible, setIsWipRedirectErrorVisible] = useState(false);
  const [wipRedirectError, setWipRedirectError] = useState(false);

  const renderCell = (params) => {
    const handleClickOpenPolicy = () => {
      const selectedPolicyObject = params.row;
      let request_body = {
        Area: 'Commercial Lines',
        DetailedContext: [
          {
            name: 'businessType',
            value: 'CL',
          },
          {
            name: 'quoteNumber',
            value: params.value,
          },
        ],
      };

      request_body.TypeOfRedirect = 'Wip CL Resume Quote';
      if (selectedPolicyObject.tranId !== undefined) {
        request_body.DetailedContext.push(
          {
            name: 'id',
            value: selectedPolicyObject.tranId,
          },
          {
            name: 'system',
            value: selectedPolicyObject.system,
          },
          {
            name: 'lob',
            value: selectedPolicyObject.lob,
          },
          {
            name: 'status',
            value: selectedPolicyObject.status,
          },
          {
            name: 'tranType',
            value: selectedPolicyObject.tranType,
          },
          {
            name: 'submitInd',
            value: selectedPolicyObject.submitInd,
          },
          {
            name: 'processStatus',
            value: selectedPolicyObject.processStatus,
          },
          {
            name: 'accessMsg',
            value: selectedPolicyObject.accessMsg,
          },
          {
            name: 'flgRes',
            value: selectedPolicyObject.flgRes,
          },
          {
            name: 'risk',
            value: selectedPolicyObject.risk,
          },
          {
            name: 'agencyNumber',
            value: selectedPolicyObject.agencyNumber,
          },
        );
      }
      return redirectUrlFunction(
        request_body,
        'POST',
        null,
        setWipRedirectError,
        setIsWipRedirectErrorVisible,
      );
    };
    return !_.isNil(params.row) && params.row.tranType !== 'Quote Decline' ? (
      <Button
        aria-label="policy/qt"
        onClick={() => {
          handleClickOpenPolicy();
        }}
        color="primary">
        {params.value}
      </Button>
    ) : (
      <Typography>{params.value} </Typography>
    );
  };
  const clWipSearchResultsColumnsData = [
    {
      field: 'quotePolNum',
      headerName: 'Policy/QT/Ref #',
      editable: false,
      flex: 1,
      renderCell,
    },
    {
      field: 'insName',
      headerName: 'Named Insured/Description',
      flex: 1,
      editable: false,
    },
    {
      field: 'lob',
      headerName: 'LOB',
      type: 'number',
      flex: 1,
      editable: false,
    },
    {
      field: 'tranType',
      headerName: 'Transaction',
      type: 'string',
      flex: 1,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'number',
      flex: 1,
      editable: false,
    },
    {
      field: 'effDate',
      headerName: 'Effective Date',
      type: 'date',
      flex: 1,
      editable: false,
      valueFormatter: dataGridDateFormatter,
    },
    {
      field: 'entDate',
      headerName: 'Entered Date',
      type: 'date',
      flex: 1,
      editable: false,
      valueFormatter: dataGridDateFormatter,
    },
    {
      field: 'userID',
      headerName: 'User ID',
      type: 'number',
      flex: 1,
      editable: false,
    },
    {
      field: 'location',
      headerName: 'Agent Code',
      type: 'number',
      flex: 1,
      editable: false,
    },
  ];

  const mapTransactionMenuItems = (items) => {
    items = items.map((item) => {
      item = {
        ...item,
        code: item.tranId === '0' ? 'ALL' : item.tranId,
        value: item.tranDesc,
      };
      delete item.tranId;
      delete item.tranDesc;
      return item;
    });
    return items;
  };

  const [clWipTransactionMenuItems, setClWipTransactionMenuItems] = useState([]);
  const getTransactionsUrl = `${GET_TRANSACTIONS_URL}${GET_COMMERCIAL_LINES}`;
  const [{ data: response, loading }] = useAxios(getTransactionsUrl);
  useEffect(() => {
    !loading && setClWipTransactionMenuItems(mapTransactionMenuItems(response));
  }, [loading, response]);

  // CL Wip State variables
  const [clWipSearchResultsColumns] = useState(clWipSearchResultsColumnsData);
  const [fetchingData, setFetchingData] = useState(true);
  const [clWipSearch, setClWipSearch] = useState();
  const [clWipSearchLoading, setClWipSearchLoading] = useState(true);
  const [clWipSearchError, setClWipSearchError] = useState(null);
  const [clWipAgentCodeValue, setClWipAgentCodeValue] = useState('');

  const [clWipSearchType, setClWipSearchType] = useState('enterDate');
  const [clWipSearchTypeValue, setClWipSearchTypeValue] = useState('');
  const [clWipEntryStartDate, setClWipEntryStartDate] = useState(
    moment().subtract(7, 'days').format('MM-DD-YYYY'),
  );
  const [clWipEntryEndDate, setClWipEntryEndDate] = useState(moment().format('MM-DD-YYYY'));

  const [clWipEffStartDate, setClWipEffStartDate] = useState(moment().format('MM-DD-YYYY'));
  const [clWipEffEndDate, setClWipEffEndDate] = useState(
    moment().add(7, 'days').format('MM-DD-YYYY'),
  );
  const [isAgentLookupModalOpen, setAgentLookupModalOpen] = useState(false);

  const clWipProductLinesDefaultOptionValue = 'All';
  const clWipProductLinesDefaultOptionCode = '0';
  const [clWipLob, setClWipLob] = useState(clWipProductLinesDefaultOptionCode);
  const [clWipLobIsDisabled, setClWipLobIsDisabled] = useState(false);
  const [clWipProductLines, setClWipProductLines] = useState([]);
  const productLinesUrl = `${GET_CL_WIP_PRODUCT_LINES_URL}`;
  const [{ data: productLinesData, loading: productLinesLoading, error: productLinesError }] =
    useAxios({
      url: `${productLinesUrl}`,
    });

  console.error(productLinesError);

  const getClWipProductLines = () => {
    return clWipProductLines;
  };
  const [clWipTransaction, setClWipTransaction] = useState('ALL');
  const [clWipTransactionIsDisabled, setClWipTransactionIsDisabled] = useState(false);

  const handleChangeClWipSearchType = (event) => {
    const lob = event.target.value;
    setClWipLobIsDisabled(false);
    setClWipSearchTypeValue('');
    setClWipLob(clWipProductLinesDefaultOptionCode);
    setClWipTransaction('ALL');
    setClWipAgentCodeValue('');
    setClWipSearchType(lob);
  };
  const handleChangeClWipSearchTypeValue = (event) => {
    setClWipSearchTypeValue(event.target.value);
  };
  const handleChangeClWipEntryStartDate = (date) => {
    setClWipEntryStartDate(moment(date).format('MM-DD-YYYY'));
  };
  const handleChangeClWipEntryEndDate = (date) => {
    setClWipEntryEndDate(moment(date).format('MM-DD-YYYY'));
  };
  const handleChangeClWipEffStartDate = (date) => {
    setClWipEffStartDate(moment(date).format('MM-DD-YYYY'));
  };
  const handleChangeClWipEffEndDate = (date) => {
    setClWipEffEndDate(moment(date).format('MM-DD-YYYY'));
  };
  const handleChangeClWipLob = (event) => {
    setClWipLob(event.target.value);
  };
  const handleChangeClWipTransaction = (event) => {
    setClWipTransaction(event.target.value);
  };
  const handleChangeClWipAgentCodeValue = (event) => {
    setClWipAgentCodeValue(event.target.value);
  };
  const handleAgentLookupModalOpen = () => {
    setAgentLookupModalOpen(true);
  };

  const buildClWipSearchEndpoint = () => {
    let endpoint;

    // Agent Filter
    const agentCode = clWipAgentCodeValue ? `&agency=${clWipAgentCodeValue}` : '';

    // LOB Filter
    const lob = `&lob=${clWipLob}`;

    // Transaction Filter
    const mappedTransaction = clWipTransaction === 'ALL' ? '0' : clWipTransaction; // if transaction is ALL then send 0
    const transaction = mappedTransaction ? `tran=${mappedTransaction}` : '';

    switch (clWipSearchType) {
      case 'insuredName': {
        endpoint = `&${transaction}&searchCriteria=insuredName&searchValue=${clWipSearchTypeValue}${agentCode}${lob}`;
        break;
      }
      case 'quote': {
        endpoint = `&${transaction}&searchCriteria=quote&searchValue=${clWipSearchTypeValue}${agentCode}${lob}`;
        break;
      }
      case 'policyNumber': {
        endpoint = `&${transaction}&searchCriteria=policyNumber&searchValue=${clWipSearchTypeValue}${agentCode}${lob}`;
        break;
      }
      case 'effectiveDate': {
        endpoint = `&${transaction}&searchCriteria=effectiveDate&startDate=${clWipEffStartDate}&endDate=${clWipEffEndDate}${lob}${agentCode}`;
        break;
      }
      default: {
        endpoint = `&${transaction}&searchCriteria=enterDate&startDate=${clWipEntryStartDate}&endDate=${clWipEntryEndDate}${lob}${agentCode}`;
        break;
      }
    }
    return endpoint;
  };

  const submitClWip = () => {
    setFetchingData(true);
    setClWipSearch();
    setClWipSearchError(null);
    setClWipSearchLoading(true);
    const CL_WIP_ENDPOINT = buildClWipSearchEndpoint();
    axios(`${API_CLS_WIP_BASE_URL}${CL_WIP_ENDPOINT}`)
      .then((results) => {
        setClWipSearchLoading(false);
        const WorkInProgress = results.data.WorkInProgress;
        const resWithId = WorkInProgress.map((el) => {
          return { id: cuid(), ...el };
        });
        setFetchingData(false);
        setClWipSearch(resWithId);
      })
      .catch((error) => {
        console.error(error);
        setClWipSearchError(error);
      });
  };

  const resetClWip = () => {
    setClWipEntryStartDate(moment().subtract(7, 'days').format('MM-DD-YYYY'));
    setClWipEntryEndDate(moment().format('MM-DD-YYYY'));
    setClWipEffStartDate(moment().format('MM-DD-YYYY'));
    setClWipEffEndDate(moment().add(7, 'days').format('MM-DD-YYYY'));
    setClWipSearchType('enterDate');
    setClWipAgentCodeValue('');
    setClWipLob(clWipProductLinesDefaultOptionCode);
    setClWipTransaction('ALL');
    setClWipLobIsDisabled(false);
    setClWipTransactionIsDisabled(false);
    setClWipSearchTypeValue('');
  };

  const clState = {
    clWipAgentCodeValue,
    clWipEffEndDate,
    clWipEffStartDate,
    clWipEntryEndDate,
    clWipEntryStartDate,
    clWipLob,
    clWipTransaction,
    clWipTransactionIsDisabled,
    clWipLobIsDisabled,
    clWipTransactionMenuItems,
    clWipSearch,
    clWipSearchError,
    clWipSearchLoading,
    clWipSearchResultsColumns,
    clWipSearchType,
    clWipSearchTypeValue,
    clWipProductLines,
    fetchingData,
    isAgentLookupModalOpen,
    isWipRedirectErrorVisible,
    wipRedirectError,
  };

  const callBackFunctions = {
    handleChangeClWipAgentCodeValue,
    handleChangeClWipEffEndDate,
    handleChangeClWipEffStartDate,
    handleChangeClWipEntryEndDate,
    handleChangeClWipEntryStartDate,
    handleChangeClWipLob,
    handleChangeClWipTransaction,
    handleChangeClWipSearchType,
    handleChangeClWipSearchTypeValue,
    submitClWip,
    resetClWip,
    getClWipProductLines,
    handleAgentLookupModalOpen,
    setAgentLookupModalOpen,
    setClWipAgentCodeValue,
  };

  // Tell ScreenSmith to load components it does not know about (components only used in this component)
  const LocalComponents = {
    AgentLookupModal,
  };

  useEffect(() => {
    submitClWip();
  }, []);

  useEffect(() => {
    if (!productLinesLoading) {
      const productLinesMenuItems = productLinesData.map((el) => {
        return {
          code: el.numericCodeForWIP,
          value: el.productLineName,
        };
      });
      const productLineMenuItemsWithDefaultOption = [
        {
          code: clWipProductLinesDefaultOptionCode,
          value: clWipProductLinesDefaultOptionValue,
        },
        ...productLinesMenuItems,
      ];

      setClWipProductLines(productLineMenuItemsWithDefaultOption);
    }
  }, [productLinesLoading]);

  if (loadingSd) return <PLSWipSkeleton />;

  return (
    !_.isNil(UiDefinitionsData) && (
      <>
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={clState}
          componentMap={LocalComponents}
        />
      </>
    )
  );
};

export default CLSWip;
