const ROUTE_AGENCY_TOOLKIT = '/agency-toolkit';
const ROUTE_AGENCY_TOOLKIT_ABSREPORTS = '/absreports';
const ROUTE_AGENCY_TOOLKIT_ANNUALMILEAGEVALIDATIONREPORT = '/annual-mileage-validation-report';
const ROUTE_AGENCY_TOOLKIT_APNEREPORT = '/apnereport';
const ROUTE_AGENCY_TOOLKIT_BMREPORTS = '/bmreports';
const ROUTE_AGENCY_TOOLKIT_CCREPORTS = '/ccreports';
const ROUTE_AGENCY_TOOLKIT_DBCREPORT = '/dbcreport';
const ROUTE_AGENCY_TOOLKIT_LOSSESBYAGENTREPORTS = '/lbareports';
const ROUTE_AGENCY_TOOLKIT_PLRREPORTS = '/plrreports';
const ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS = '/tareports';

const ROUTE_ANNOUNCEMENTS = '/announcements';

const ROUTE_APPLICATION_ADMIN = '/application-admin';

const ROUTE_BILLING_SERVICES = '/billing-services';
const ROUTE_BILLING_SERVICES_REPORTS = `${ROUTE_BILLING_SERVICES}/reports`;
const ROUTE_BILLING_SERVICES_REPORTS_PAYMENTS_MADE = `${ROUTE_BILLING_SERVICES_REPORTS}/payments-made`;
const ROUTE_BILLING_SERVICES_REPORTS_PAYMENT_BALANCE = `${ROUTE_BILLING_SERVICES_REPORTS}/payment-balance`;
const ROUTE_COMMERCIAL_LINES_AUDIT_FORMS = `/commercial-lines-audit-forms`;
const ROUTE_COMMERCIAL_LINES_QUOTING = '/commercial-lines-quoting';
const ROUTE_ACCOUNT_DETAIL = `/account-detail`;
const ROUTE_CL_PRODUCT_DETAIL = `${ROUTE_COMMERCIAL_LINES_QUOTING}/product-detail`;
const ROUTE_CLAIMS_INQ = '/claims-inq';
const ROUTE_COMMERCIAL_LINES_SERVICES = '/commercial-lines-services';
const ROUTE_DASHBOARD = '/dashboard';
const ROUTE_FARMRANCH = '/farmranch';
// const ROUTE_FIND_PRODUCT_TO_QUOTE = `${ROUTE_COMMERCIAL_LINES_QUOTING}/get-started`;
const ROUTE_FLOOD = `/flood`;
const ROUTE_HOME = '/';
const ROUTE_NEWS_ARTICLE_ADMIN = '/news-articles';
const ROUTE_NEWS_ARCHIVED = `${ROUTE_NEWS_ARTICLE_ADMIN}/home-page-archived`;
const ROUTE_NEWS_ADD = '/news-articles/add';
const ROUTE_NEWS_ARTICLE_ADMIN_REORDER = '/news-articles/reorder';
const ROUTE_NEWS_ARTICLE_ADMIN_SEARCH_EDIT = '/news-articles/search-edit';
const ROUTE_PERSONAL_LINES_SERVICES = '/personal-lines-services';
const ROUTE_PERSONAL_LINES_UMBRELLA_RATING_TOOLS_FORM = '/personalLinesUmbrellaRatingToolAndForm';
const ROUTE_PL_UMBRELLA = '/plumbrella';
const ROUTE_QUOTING_ONE_POC = '/quoting-one-poc';
const ROUTE_STORM_REFERENCE = '/storm-reference';
const ROUTE_UNDERWRITING_COMMERCIAL_LINES = '/underwriting/commercial-lines';
const ROUTE_UNDERWRITING_PERSONAL_LINES = '/underwriting/personal-lines';
const ROUTE_USER_ADMIN = '/user-admin';
const ROUTE_USER_ADMIN_ADD_GROUP = '/add-group';
const ROUTE_USER_ADMIN_ADD_USER = '/add-user';
const ROUTE_USER_ADMIN_EDIT_USER = '/edit-user';
const ROUTE_USER_ADMIN_GROUP_INFORMATION = '/group-information';
const ROUTE_USER_ADMIN_SEARCH_USER = '/search-user';
const ROUTE_USER_ADMIN_VIEW_AGENCY_INFO = '/view-agency-info';
const ROUTE_USER_ADMIN_VIEW_CHANGE_HISTORY = '/view-change-history';
const ROUTE_CLIX = '/clix';
const ROUTE_WILDCARD = '/*';

export {
  ROUTE_AGENCY_TOOLKIT_ABSREPORTS,
  ROUTE_AGENCY_TOOLKIT_ANNUALMILEAGEVALIDATIONREPORT,
  ROUTE_AGENCY_TOOLKIT_APNEREPORT,
  ROUTE_AGENCY_TOOLKIT_BMREPORTS,
  ROUTE_AGENCY_TOOLKIT_CCREPORTS,
  ROUTE_AGENCY_TOOLKIT_DBCREPORT,
  ROUTE_AGENCY_TOOLKIT_LOSSESBYAGENTREPORTS,
  ROUTE_AGENCY_TOOLKIT_PLRREPORTS,
  ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS,
  ROUTE_AGENCY_TOOLKIT,
  ROUTE_ANNOUNCEMENTS,
  ROUTE_APPLICATION_ADMIN,
  ROUTE_BILLING_SERVICES,
  ROUTE_BILLING_SERVICES_REPORTS,
  ROUTE_BILLING_SERVICES_REPORTS_PAYMENTS_MADE,
  ROUTE_BILLING_SERVICES_REPORTS_PAYMENT_BALANCE,
  ROUTE_CL_PRODUCT_DETAIL,
  ROUTE_ACCOUNT_DETAIL,
  ROUTE_CLAIMS_INQ,
  ROUTE_COMMERCIAL_LINES_AUDIT_FORMS,
  ROUTE_COMMERCIAL_LINES_SERVICES,
  ROUTE_DASHBOARD,
  ROUTE_FARMRANCH,
  // ROUTE_FIND_PRODUCT_TO_QUOTE,
  ROUTE_FLOOD,
  ROUTE_HOME,
  ROUTE_NEWS_ADD,
  ROUTE_NEWS_ARCHIVED,
  ROUTE_NEWS_ARTICLE_ADMIN,
  ROUTE_NEWS_ARTICLE_ADMIN_REORDER,
  ROUTE_NEWS_ARTICLE_ADMIN_SEARCH_EDIT,
  ROUTE_PERSONAL_LINES_SERVICES,
  ROUTE_PERSONAL_LINES_UMBRELLA_RATING_TOOLS_FORM,
  ROUTE_PL_UMBRELLA,
  ROUTE_QUOTING_ONE_POC,
  ROUTE_STORM_REFERENCE,
  ROUTE_UNDERWRITING_COMMERCIAL_LINES,
  ROUTE_UNDERWRITING_PERSONAL_LINES,
  ROUTE_USER_ADMIN,
  ROUTE_USER_ADMIN_ADD_GROUP,
  ROUTE_USER_ADMIN_EDIT_USER,
  ROUTE_USER_ADMIN_ADD_USER,
  ROUTE_USER_ADMIN_GROUP_INFORMATION,
  ROUTE_USER_ADMIN_SEARCH_USER,
  ROUTE_USER_ADMIN_VIEW_AGENCY_INFO,
  ROUTE_USER_ADMIN_VIEW_CHANGE_HISTORY,
  ROUTE_CLIX,
  ROUTE_WILDCARD,
};
