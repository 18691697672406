import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { ScreenSmith } from '../../../ScreenSmith';
import BillingServicesReportsPaymentBalanceSkeleton from './BillingServicesReportsPaymentBalanceSkeleton';
import TotalsTable from './TotalsTable'

import useScreenDefinition from '../../../../hooks/useScreenDefinition';

import { handleDateOnChange, handleOnClickGenerateReport, detailsColumns, detailsOptions } from './BillingServicesReportsPaymentBalance.api'

const componentMap = { TotalsTable }

const BillingServicesReportsPaymentBalance = () => {
  const rawFromDate = moment().subtract(3, 'days')
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(
    `BillingServicesReportsPaymentBalance`,
  );
  const [formData, setFormData] = useState({
    fromDate: moment(rawFromDate).format('MM/DD/yyyy'),
    toDate: moment().format('MM/DD/yyyy'),
  });
  const [reportContentData, setReportContentData] = useState({
    reportDetails: null,
    reportTotals: [],
    isVisible: false,
    loading: false,
    alert: {
      message: null,
      severity: "info",
      isVisible: false
    }
  })

  const localStateMap = {
    fromDate: formData.fromDate,
    toDate: formData.toDate,
    reportsVisible: reportContentData.isVisible,
    reportsLoading: reportContentData.loading,
    detailsColumns,
    detailsOptions,
    detailsData: reportContentData.reportDetails,
    totalsFromDate: formData.fromDate,
    totalsToDate: formData.toDate,
    totalsData: reportContentData.reportTotals,
    alertSeverity: reportContentData.alert.severity,
    alertMessage: reportContentData.alert.message,
    alertVisible: reportContentData.alert.isVisible,

  };

  const callBackFunctions = {
    handleOnChangeFromDate: (date) => handleDateOnChange(date, 'fromDate', formData, setFormData),
    handleOnChangeToDate: (date) => handleDateOnChange(date, 'toDate', formData, setFormData),
    handleOnClickGenerateReport: () => handleOnClickGenerateReport(formData, setReportContentData),
  };

  if (loadingSd) return <BillingServicesReportsPaymentBalanceSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={componentMap} />
      )}
    </>
  );
};

export default BillingServicesReportsPaymentBalance;
