import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Divider from '@material-ui/core/Divider';

import { AOButton } from '../AOButton';
import { ROUTE_USER_ADMIN_EDIT_USER } from '../../constants/routes';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  container: {
    maxHeight: '400px',
    overflow: 'auto',
  },
});

const Row = (props) => {
  const { columns, row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell width={100}>
          {row?.agencyNames.length > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>

        <TableCell>
          <AOButton
            route={`${ROUTE_USER_ADMIN_EDIT_USER}?userName=${row?.userName}`}
            color="primary"
            id={`search-user-result-${row?.userName?.toLowerCase()}`}>
            {row?.userName}
          </AOButton>
        </TableCell>

        {columns.map(
          (column, index) =>
            index > 0 && (
              <TableCell key={`${index}-${row.userName}`}>{row[column.field]}</TableCell>
            ),
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} pl={18}>
              {row?.agencyNames.map((agency, index) => (
                <Typography key={`${index}-${agency}`}>
                  <Box fontSize={'0.75rem'}>{agency}</Box>
                </Typography>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  columns: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
};

const CollapsibleTable = ({ columns, rows }) => {
  const classes = useRowStyles();

  return rows.length ? (
    <>
      <Divider />
      <Box display={'flex'} justifyContent="space-between" alignItems="center" my={2}>
        <Typography variant="h6">Search Result </Typography>
      </Box>

      <TableContainer component={Paper} className={classes.container}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column, index) => (
                <TableCell key={`${index}-${column.field}`}>{column.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={`${row?.userName}`} columns={columns} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <Typography color={'error'}>No user found for search criteria.</Typography>
  );
};

CollapsibleTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

export default CollapsibleTable;
