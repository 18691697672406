import React from 'react';
import cuid from 'cuid';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { InputLabel, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core';

import { StyledAOSelect } from './AOSelect.styled';

const AOSelect = ({
  children,
  disabled,
  displayLabel,
  id,
  inputLabel,
  invalidSearch,
  labelId,
  menuItems,
  value,
  ...props
}) => {
  const renderedMenuItems = (_displayLabel, _menuItems) => {
    return _menuItems.map(({ value, code }) => (
      <MenuItem key={cuid()} name={value} value={code} style={{ minHeight: '2rem' }}>
        {value}
      </MenuItem>
    ));
  };

  return (
    <StyledAOSelect disabled={disabled}>
      <FormControl variant="outlined" error={invalidSearch ? true : false}>
        <InputLabel id={labelId}>{inputLabel}</InputLabel>
        <Select
          labelId={labelId}
          disabled={disabled}
          label={inputLabel}
          id={id}
          value={value}
          autoWidth
          {...props}>
          {displayLabel && (
            <MenuItem value="" disabled>
              <em>{displayLabel}</em>
            </MenuItem>
          )}
          {!_.isNil(menuItems) ? renderedMenuItems(displayLabel, menuItems) : children}
        </Select>
        {invalidSearch && <FormHelperText>{invalidSearch}</FormHelperText>}
      </FormControl>
    </StyledAOSelect>
  );
};

AOSelect.propTypes = {
  Action: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  displayLabel: PropTypes.string,
  id: PropTypes.string,
  inputLabel: PropTypes.string,
  invalidSearch: PropTypes.string,
  labelId: PropTypes.string,
  menuItems: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default AOSelect;
