import React, { useState, useContext } from 'react';
import _ from 'lodash';
import { ScreenSmith } from '../ScreenSmith';
import UnderwritingCommercialLinesSkeleton from './UnderwritingCommercialLinesSkeleton';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';
import { handleFileDownload } from '../CommonUtilityServices/DownloadFileService';

import AppConfigContext from '../../contexts/appConfig.context';

const UnderwritingCommercialLines = () => {
  const { legacyUrl } = useContext(AppConfigContext);

  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('UnderwritingCommercialLinesFrame');
  const [uclAlertIsVisible, setUclAlertIsVisible] = useState(false);
  const [uclAlertMessage] = useState(null);
  // Main Street Station Guide State
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [isDownloadingFileErrorVisible, setIsDownloadingFileErrorVisible] = useState(false);
  const [downloadingFileError, setDownloadingFileError] = useState(false);

  // Common functions
  const resetAlerts = () => {
    setUclAlertIsVisible(false);
    setIsDownloadingPdfErrorVisible(false);
    setIsDownloadingFileErrorVisible(false);
  };
  const handleClickProxyRedirect = (event) => {
    resetAlerts();
    const routeUrl = event.currentTarget.getAttribute('data-route');
    window.open(`${legacyUrl}${routeUrl}`, '_blank');
    // proxyRedirect(routeUrl, 'GET', 'newWindow', setUclAlertIsVisible, setUclAlertMessage);
  };

  // Main Street Station Guide State functions
  const handleClickDownloadPdf = (event) => {
    resetAlerts();
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');

    handlePdfDownload(
      'GET',
      null,
      pdfUrl,
      setLoadingPdf,
      setDownloadingPdfError,
      setIsDownloadingPdfErrorVisible,
      'newWindow',
    );
  };

  const handleClickDownloadFile = (event) => {
    resetAlerts();
    const fileUrl = event.currentTarget.getAttribute('data-file');
    handleFileDownload(
      fileUrl,
      setLoadingFile,
      setDownloadingFileError,
      setIsDownloadingFileErrorVisible,
    );
  };

  const localStateMap = {
    uclAlertIsVisible,
    uclAlertMessage,
    loadingPdf,
    isDownloadingPdfErrorVisible,
    downloadingPdfError,
    loadingFile,
    isDownloadingFileErrorVisible,
    downloadingFileError,
  };

  const callBackFunctions = {
    handleClickProxyRedirect,
    handleClickDownloadPdf,
    handleClickDownloadFile,
  };

  if (loadingSd) return <UnderwritingCommercialLinesSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default UnderwritingCommercialLines;
