const primary = {
  main: '#0B3B60',
  dark: '#001536',
  light: '#5093B5',
};
const secondary = {
  main: '#007899',
  dark: '#005272',
  light: '#32C0F1',
};

const denimBlue = {
  50: '#e2eff3',
  100: '#b8d7e4',
  200: '#8fbdd2',
  300: '#6aa4c0',
  400: '#5093b5',
  500: '#3982ab',
  600: '#226690',
  700: '#19567f',
  800: '#0b3b60',
  900: '#001536',
  A100: '#b9dbf1',
  A200: '#78b9e4',
  A400: '#2682c1',
  A700: '#164b6f',
};

const bahamaBlue = {
  50: '#e2f6ff',
  100: '#b6e8fe',
  200: '#85d9fc',
  300: '#55caf7',
  400: '#32c0f1',
  500: '#24b5ea',
  600: '#1aa6d5',
  700: '#0091ba',
  800: '#007ea1',
  900: '#005272',
  A100: '#b5efff',
  A200: '#66deff',
  A400: '#00bbef',
  A700: '#006f8d',
};

const jade = {
  700: '#037C6D',
};

const defaultCountButton = '#616161';

export { primary, secondary, denimBlue, bahamaBlue, jade, defaultCountButton };
