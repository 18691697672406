import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import OpenInNew from '@material-ui/icons/OpenInNew';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';

import AOButtonLoadingContainer from './AOButtonLoadingContainer';

const IconComponents = {
  ArrowBack,
  ArrowForward,
  OpenInNew,
  PictureAsPdf,
};

const AOBaseButton = ({ loading, children, endIcon, startIcon, ...otherProps }) => {
  return (
    <AOButtonLoadingContainer loading={loading}>
      <Button
        disabled={loading}
        startIcon={
          startIcon &&
          IconComponents[startIcon] &&
          React.createElement(IconComponents[startIcon], null, null)
        }
        endIcon={
          endIcon &&
          IconComponents[endIcon] &&
          React.createElement(IconComponents[endIcon], null, null)
        }
        {...otherProps}>
        {children}
      </Button>
    </AOButtonLoadingContainer>
  );
};

AOBaseButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  endIcon: PropTypes.string,
  startIcon: PropTypes.string,
};

export default AOBaseButton;
