import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';

const AgentDisplay = ({ agentDisplayName, ...props }) => {
  return (
    <Box
      mx={2}
      style={{ height: `64px` }}
      display="flex"
      flexDirection="column"
      justifyContent="center">
      <Typography {...props} color="primary" variant="subtitle1">
        {agentDisplayName ? agentDisplayName : 'Agent Name'}
      </Typography>
    </Box>
  );
};

AgentDisplay.propTypes = {
  agentDisplayName: PropTypes.string,
};

export default AgentDisplay;
