import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Feature } from '../../Feature';
import featureFlags from '../../../constants/featureFlags';
import {
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Button,
  FormGroup,
  Checkbox,
} from '@material-ui/core';

import { RowCol, Row, Col } from '../../ScreenSmith';
import { Headline } from '../../Headline';
import { AOCard } from '../../AOCard';
import { AOTextField } from '../../AOTextField';
import { AODatePicker } from '../../AODatePicker';
// import { AOCheckbox } from '../../AOCheckbox';
import { AOAlert } from '../../AOAlert';
import { AOTransferList } from '../../AOTransferList';
import { Visible } from '../../Visible';

import { getStates, submitTextNews } from './AddNewsArticle.api';

import {
  handleFromDateOnChange,
  handleOnChange as handleOnChangeService,
  handleOnChangeCheckbox as handleOnChangeCheckboxService,
  handleToDateOnChange,
} from '../../CommonUtilityServices/EventHandlerService';

const AddNewsArticle = () => {
  const formDataInit = {
    title: '',
    endDate: null,
    startDate: moment().format('MM/DD/YYYY'),
    displaySectionError: false,
    displayStatus: "D",
    sectionString: "",
    linkToDocOrText: "",
    displaySectionCLSYellowBar: false,
    displaySectionHomePage: false,
    displaySectionPLSYellowBar: false,
    displaySectionCLSNewsArticles: false,
    alert: {
      isVisible: false,
    }
  };
  const [formData, setFormData] = useState({
    title: '',
    endDate: null,
    startDate: moment().format('MM/DD/YYYY'),
    displaySectionError: false,
    sectionString: [],
    displayStatus: "D",
    linkToDocOrText: "",
    alert: {
      isVisible: false,
      severity: 'error',
      message: 'Please fill out the required fields to add a news article.',
    },
  });
  const [formValidationData, setfFormValidationData] = useState({});
  const [availableStates, setAvailableStates] = useState([]);
  const [checkedStates, setCheckedStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [displaySectionCLSYellowBar, setDisplaySectionCLSYellowBar] = useState(false);
  const [displaySectionHomePage, setDisplaySectionHomePage] = useState(false);
  const [displaySectionPLSYellowBar, setDisplaySectionPLSYellowBar] = useState(false);
  const [displaySectionCLSNewsArticles, setDisplaySectionCLSNewsArticles] = useState(false);
  const [displaySectionPLSNewsArticles, setDisplaySectionPLSNewsArticles] = useState(false);



  /* Callback fired when dropdown value changes */
  const handleOnChange = (e) => {
    handleOnChangeService(e, formData, setFormData);
  };

  /* remove error if a value is entered */
  const handleOnBlur = (e) => {
    const {
      target: { name },
    } = e;
    if (formValidationData[`${name}Error`]) {
      setfFormValidationData((prevValue) => ({ ...prevValue, [`${name}Error`]: false }));
    }
  };

  /* Callback fired when checkbox value changes */
  const handleOnChangeCheckbox = (e) => {
    const {
      target: { name, checked },
    } = e;
    console.log(`checkbox name: ${name} - ${checked}`);

    if (checked) {
      if (name === "0") { setDisplaySectionHomePage(e.currentTarget.checked) }
      if (name === "1") { setDisplaySectionPLSYellowBar(e.currentTarget.checked) }
      if (name === "2") { setDisplaySectionCLSYellowBar(e.currentTarget.checked) }
      if (name === "3") { setDisplaySectionPLSNewsArticles(e.currentTarget.checked) }
      if (name === "4") { setDisplaySectionCLSNewsArticles(e.currentTarget.checked) }


      setFormData((prevValue) => ({
        ...prevValue,
        sectionString: [...prevValue.sectionString, name],
      }));

    } else {
      const filteredsectionString = _.filter(formData.sectionString, (n) => n !== name);
      setFormData((prevValue) => ({
        ...prevValue,
        sectionString: filteredsectionString,
      }));
    }
    handleOnChangeCheckboxService(e, setFormData);
  };

  const handleOnBlurCheckbox = () => {
    if (formData.sectionString.length > 0) {
      setfFormValidationData((prevValue) => ({ ...prevValue, [`displaySectionError`]: false }));
    }
    if (formData.displayStatus !== undefined && formData.displayStatus.length > 0) {
      setfFormValidationData((prevValue) => ({ ...prevValue, [`displayStatusError`]: false }));
    }

  };

  /* Callback fired when the From Date value changes. */
  const handleOnChangeStartDate = (date) => {
    handleFromDateOnChange(date, formData, setFormData, 'startDate');
  };

  /* Callback fired when the To Date value changes. */
  const handleOnChangeEndDate = (date) => {
    handleToDateOnChange(date, formData, setFormData, 'endDate');
  };

  const handleStatesSetLeft = (e) => {
    setAvailableStates(e);
  };

  const handleStatesSetRight = (e) => {
    setSelectedStates(e);
    setFormData({ ...formData, states: e });
  };

  const validateRequiredFields = () => {
    let formValidationData = {};

    !formData.title && (formValidationData = { ...formValidationData, titleError: true, displayError: true });
    !formData.startDate && (formValidationData = { ...formValidationData, startDateError: true, displayError: true });
    formData.sectionString.length < 1 &&
      (formValidationData = { ...formValidationData, displaySectionError: true, displayError: true });
    (formData.states === undefined || formData.states.length < 1) &&
      (formValidationData = { ...formValidationData, statesError: true, displayError: true });


    setfFormValidationData((prevValue) => ({ ...prevValue, ...formValidationData }));

    return formValidationData;
  };


  const resetToBegin = () => {
    console.log(formData)
    setCheckedStates([]);
    setSelectedStates([])
    setFormData(formDataInit);
    setDisplaySectionHomePage(false);
    setDisplaySectionCLSYellowBar(false);
    setDisplaySectionPLSYellowBar(false);
    setDisplaySectionCLSNewsArticles(false);
    setDisplaySectionPLSNewsArticles(false);
    setFormData((prevValue) => ({
      ...prevValue,
      states: [],
      sectionString: "",

    }));

    console.log(formData)
  };

  const handleClickSubmitText = async () => {
    const requiredFieldsValid = validateRequiredFields();
    if (requiredFieldsValid.displayError == undefined || !requiredFieldsValid.displayError) {
      try {

        console.log({ formData });
        setFormData((prevValue) => ({
          ...prevValue,
          alert: {
            ...prevValue.alert,
            isVisible: false,
          },
        }));
        const requestBody = {
          startDate: formData.startDate,
          type: formData.linkToDocOrText,
          endDate: formData.endDate,
          text: formData.text,
          sectionsString: formData.sectionString && formData.sectionString.join(','),
          status: formData.displayStatus,
          selectedStatesString: formData.states && formData.states.join(','),
          title: formData.title,
        };
        await submitTextNews(requestBody);
        window.alert('Successfully loaded news item.');
        resetToBegin()
      } catch (error) {
        window.alert(error);
      }
    } else {
      setFormData((prevValue) => ({
        ...prevValue,
        alert: {
          ...prevValue.alert,
          isVisible: true,
        },
      }));
    }
  };

  useEffect(async () => {
    const getStatesForNews = await getStates();
    setAvailableStates(getStatesForNews);
    if (
      formData.title === "" ||
      formData.sectionString.length === 0 ||
      formData.states === undefined || formData.states.length === 0 ||
      formData.displayError !== undefined || formData.displayError ||
      formData.text === undefined || formData.text === ""
    ) {
      setSubmitDisable(true);
    } else {
      setSubmitDisable(false);
    }
  }, [formData]);

  console.log(formData);
  console.log(formValidationData);

  return (
    <Feature name={featureFlags.ACCESS_NEWS_ADMIN}>
      <RowCol>
        <RowCol>
          <Headline variant="h1" title="Add News Article" />
        </RowCol>
        <RowCol mb={2}>
          <AOCard>
            <Row mb={2}>
              <Col xs={12}>
                <Typography variant="h6">News Category Agents</Typography>
              </Col>
            </Row>
            <Row mb={4}>
              <Col xs={12} md={6}>
                <RowCol mb={4}>
                  <AOTextField
                    id={'title'}
                    label={'Title'}
                    value={formData.title}
                    name={'title'}
                    required
                    error={formValidationData.titleError}
                    onChange={(e) => handleOnChange(e)}
                    onBlur={(e) => handleOnBlur(e)}
                  />
                </RowCol>
                <RowCol mb={4}>
                  <AODatePicker
                    id={`start-datepicker`}
                    name={`startDate`}
                    label={`Display Start Date`}
                    value={formData.startDate}
                    error={formValidationData.startDateError}
                    onChange={(date) => handleOnChangeStartDate(date)}
                    required
                  />
                </RowCol>
                <RowCol mb={4}>
                  <AODatePicker
                    id={`end-datepicker`}
                    name={`endDate`}
                    label={`Display End Date`}
                    value={formData.endDate}
                    onChange={(date) => handleOnChangeEndDate(date)}
                  />
                </RowCol>
              </Col>
              <Col xs={12} md={6}>
                <FormControl
                  required
                  component="fieldset"
                  error={formValidationData.displaySectionError}>
                  <FormLabel component="legend">Display Section</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={displaySectionHomePage}
                          onChange={(e) => handleOnChangeCheckbox(e)}
                          onBlur={(e) => handleOnBlurCheckbox(e)}
                          name="0"
                        />
                      }
                      label="Home Page"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={displaySectionPLSYellowBar}
                          onChange={(e) => handleOnChangeCheckbox(e)}
                          onBlur={(e) => handleOnBlurCheckbox(e)}
                          name="1"
                        />
                      }
                      label="Personal Lines Services - Yellow Bar"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={displaySectionCLSYellowBar}
                          onChange={(e) => handleOnChangeCheckbox(e)}
                          onBlur={(e) => handleOnBlurCheckbox(e)}
                          name="2"
                        />
                      }
                      label="Commercial Lines Services - Yellow Bar"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={displaySectionPLSNewsArticles}
                          onChange={(e) => handleOnChangeCheckbox(e)}
                          onBlur={(e) => handleOnBlurCheckbox(e)}
                          name="3"
                        />
                      }
                      label="Personal Line Services - News Articles"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={displaySectionCLSNewsArticles}
                          onChange={(e) => handleOnChangeCheckbox(e)}
                          onBlur={(e) => handleOnBlurCheckbox(e)}
                          name="4"
                        />
                      }
                      label="Commercial Line Services - News Articles"
                    />
                  </FormGroup>
                </FormControl>
              </Col>
            </Row>
            <RowCol mb={8}>

              <RowCol>
                <AOTransferList
                  required
                  checked={checkedStates}
                  left={availableStates}
                  leftLabel={'Available States'}
                  right={selectedStates}
                  rightLabel={'* Selected States'}
                  setLeft={(e) => handleStatesSetLeft(e)}
                  setRight={(e) => handleStatesSetRight(e)}
                  setChecked={setCheckedStates}
                  elevation={0}
                  error={formValidationData.statesError}
                  onBlur={(e) => handleOnBlur(e)}
                  variant="outlined"></AOTransferList>
                {selectedStates && <Box>You must select a State</Box>}
              </RowCol>

            </RowCol>
            <RowCol mb={6}>
              <FormControl component="fieldset" required error={formValidationData.displayStatusError}>
                <FormLabel component="legend">Display Status</FormLabel>
                <RadioGroup
                  aria-label="displayStatus"
                  name="displayStatus"
                  defaultValue="D"
                  value={formData.displayStatus}
                  onChange={(e) => handleOnChange(e)}>
                  <FormControlLabel value="D" control={<Radio />} label="Display" onBlur={(e) => handleOnBlurCheckbox(e)} />
                  <FormControlLabel value="A" control={<Radio />} label="Archive" onBlur={(e) => handleOnBlurCheckbox(e)} />
                  <FormControlLabel value="H" control={<Radio />} label="Hide" onBlur={(e) => handleOnBlurCheckbox(e)} />
                </RadioGroup>
              </FormControl>
            </RowCol>
            <RowCol>
              <RowCol mb={2}>
                <FormControl component="fieldset" required>
                  <RadioGroup
                    aria-label="displayStatus"
                    name="linkToDocOrText"
                    value={formData.linkToDocOrText}
                    onChange={(e) => handleOnChange(e)}
                    row>
                    <FormControlLabel
                      value="D"
                      control={<Radio />}
                      label="Link to Document"
                    />
                    <FormControlLabel value="T" control={<Radio />} label="Text" />
                  </RadioGroup>
                </FormControl>
              </RowCol>
              <RowCol>
                <Visible isVisible={formData.linkToDocOrText === 'T'}>
                  <AOTextField
                    multiline
                    name="text"
                    value={formData.text}
                    onChange={(e) => handleOnChange(e)}
                    label="Article Body"
                    minRows={4}
                  />
                </Visible>
              </RowCol>
            </RowCol>
            <Visible isVisible={formData.alert.isVisible}>
              <RowCol mb={2}>
                <AOAlert
                  isVisible={formData.alert.isVisible}
                  severity={formData.alert.severity}
                  message={formData.alert.message}
                />
              </RowCol>
            </Visible>

            <RowCol mb={2}>
              <Box display="flex" justifyContent="flex-end">
                <Box>
                  <Box display="flex">
                    <Box>
                      <Button color="primary" variant="contained" disabled={submitDisable} onClick={handleClickSubmitText}>
                        Submit
                      </Button>
                    </Box>
                    <Box mx={2}>
                      <Button type="reset" color="" variant="contained" onClick={resetToBegin}>
                        Reset
                      </Button>
                    </Box>
                    <Box>
                      <Button variant="outlined">Cancel</Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </RowCol>
          </AOCard>
        </RowCol>
      </RowCol>
    </Feature>
  );
};

export default AddNewsArticle;
