const featureFlags = {
  ACCESS_AGENCY_SECURITY_USER_ADMIN: 'agencySecurityUserAdministration',
  ACCESS_APNE_REPORT: 'Access_AP&E_Report',
  ACCESS_SUPER_ADMIN: 'superAdmin',
  ACCESS_TAR_REPORT: 'Access_TransactionActivity_Report',
  ACCESS_USER_ADMIN: 'userAdministration',
  ACCESS_USER_IS_AGENT: 'isAgent',
  ACCESS_USER_INTERNAL: 'internalUser',
  ACCESS_NEWS_ADMIN: 'newsAdministration',
};

export default featureFlags;
