import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

import { USER_ADMIN_CAN_EDIT, USER_ADMIN_GET_USER_INFO } from '../../../constants/api';
import { AOCircularProgress } from '../../AOCircularProgress';
import { TabPanel } from '../../TabPanel';
import { handleTabChange } from '../../CommonUtilityServices/EventHandlerService';
import EditUserInfoPanel from './EditUserTabPanels/EditUserInfoPanel';
import EditUserGroupsPanel from './EditUserTabPanels/EditUserGroupsPanel';
import EditUserAgenciesPanel from './EditUserTabPanels/EditUserAgenciesPanel';
import EditUserHistoryPanel from './EditUserTabPanels/EditUserHistoryPanel.js';

const EditUser = () => {
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [userDetails, setUserDetails] = useState();
  const [canEditCode, setCanEditCode] = useState('1');

  const { search } = useLocation();

  useEffect(async () => {
    try {
      const userName = search.split('=')[1] ?? '';

      const canEditUserUrl = `${USER_ADMIN_CAN_EDIT}/${userName}`;

      const { data: canEditCode } = await axios.get(canEditUserUrl);
      setCanEditCode(canEditCode);

      if (canEditCode !== '2') {
        const getUserDetailsUrl = `${USER_ADMIN_GET_USER_INFO}/${userName}`;
        const details = await axios.get(getUserDetailsUrl);
        setUserDetails(details.data);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  return canEditCode !== '2' ? (
    <>
      <AOCircularProgress visible={loading}>Fetching user details...</AOCircularProgress>
      <Tabs
        aria-label={'tabs-AgencyToolkit'}
        variant={'scrollable'}
        scrollButtons={'on'}
        value={currentTab}
        onChange={(e, newValue) => handleTabChange(e, newValue, setCurrentTab)}>
        <Tab
          value={0}
          id={'search-user-tab-user-info'}
          label={'User Info.'}
          aria-controls={'edit-user-tabpanel-0'}
        />
        <Tab
          value={1}
          id={'search-user-tab-agencies'}
          label={'Agencies'}
          aria-controls={'edit-user-tabpanel-1'}
        />
        <Tab
          value={2}
          id={'search-user-tab-groups'}
          label={'Groups'}
          aria-controls={'edit-user-tabpanel-2'}
        />
        <Tab
          value={3}
          id={'search-user-tab-history'}
          label={'History'}
          aria-controls={'edit-user-tabpanel-3'}
        />
      </Tabs>
      <TabPanel value={currentTab} index={0}>
        <EditUserInfoPanel userDetails={userDetails} setUserDetails={setUserDetails} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <EditUserAgenciesPanel userDetails={userDetails} setUserDetails={setUserDetails} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <EditUserGroupsPanel userDetails={userDetails} />
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        <EditUserHistoryPanel userDetails={userDetails} />
      </TabPanel>
    </>
  ) : (
    <Typography>You do not have permissions to view the page that you requested.</Typography>
  );
};

export default EditUser;
