import sub from 'date-fns/sub';

const MOMENT_DATE_FORMAT_MMDDYYYY = 'MM/DD/YYYY';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getMonthName = (month) => {
  if (month < 1 || month > 12) {
    return '';
  } else {
    return monthNames[month - 1];
  }
};

const aODatePickerMinDate = (months = 0, days = 0, years = 0) => {
  return sub(new Date(), {
    months,
    days,
    years,
  });
};

const getPastYearMonthsMenuItems = () => {
  const months = [];
  const today = new Date();

  for (let i = 0; i < 12; i++) {
    const monthYear = sub(today, { months: i, days: 0, years: 0 });
    let month = monthYear.getMonth();
    let year = monthYear.getFullYear();
    months.push({ value: `${monthNames[month]} ${year}`, code: `${month + 1}${year}` });
  }

  return months;
};

export {
  aODatePickerMinDate,
  getMonthName,
  MOMENT_DATE_FORMAT_MMDDYYYY,
  getPastYearMonthsMenuItems,
};
