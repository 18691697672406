import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledAOCircularProgress,
  StyledAOCircularProgressLogoDestination,
  StyledAOCircularProgressLoader,
} from './AOCircularProgress.styled';
import { SvgLogo } from '../SvgLogo';
import { Visible } from '../Visible';

const AOCircularProgress = ({ children, visible }) => {
  return (
    <Visible isVisible={visible}>
      <StyledAOCircularProgress style={{ zIndex: '99' }}>
        <StyledAOCircularProgressLogoDestination>
          <SvgLogo />
        </StyledAOCircularProgressLogoDestination>
        {children}
        <StyledAOCircularProgressLoader />
      </StyledAOCircularProgress>
    </Visible>
  );
};

AOCircularProgress.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
};

export default AOCircularProgress;
