import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, Paper } from '@material-ui/core';
import axios from 'axios';

import { Feature } from '../Feature';
import featureFlags from '../../constants/featureFlags';
import UserAdminViewContainer from './UserAdminViewContainer';
import { AOCard } from '../AOCard';
import { AOSelect } from '../AOSelect';
import {
  USER_ADMIN_ADD_GROUP,
  USER_ADMIN_ALL_GROUPS,
  USER_ADMIN_DELETE_GROUP,
  USER_ADMIN_GROUP_MEMBERS,
} from '../../constants/api';
import { AOTextField } from '../AOTextField';
import { AOButton } from '../AOButton';
import { AOLinkButton } from '../AOLinkButton';
import { ROUTE_USER_ADMIN_GROUP_INFORMATION } from '../../constants/routes';
import { AOAlert } from '../AOAlert';

const defaultNewGroupNameValue = { value: '', error: false };

const UserAdminAddGroup = () => {
  const [allGroups, setAllGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [newGroupNameValue, setNewGroupNameValue] = useState(defaultNewGroupNameValue);
  const [buttonLoading, setButtonLoading] = useState({ add: false, delete: false });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(async () => {
    try {
      const data = await getAllGroups();
      setAllGroups(data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getAllGroups = async () => {
    const { data } = await axios.get(USER_ADMIN_ALL_GROUPS);
    return data;
  };

  const handleAddOnClick = async () => {
    setError(null);
    setButtonLoading((prevState) => ({ ...prevState, add: true }));
    try {
      const { data } = await axios.post(USER_ADMIN_ADD_GROUP, {
        groupName: newGroupNameValue.value,
      });
      if (data === 'Success') {
        const allGroups = await getAllGroups();
        setAllGroups(allGroups);
      } else {
        setError(
          'Error adding group. Please check the group name only contains letters and does not already exist.',
        );
      }
      setNewGroupNameValue(defaultNewGroupNameValue);
    } catch (err) {
      console.error(err);
    }
    setButtonLoading((prevState) => ({ ...prevState, add: false }));
  };

  const handleClearOnClick = () => {
    setError(null);
    setNewGroupNameValue(defaultNewGroupNameValue);
  };

  const deleteGroup = async () => {
    setError(null);
    await axios.delete(`${USER_ADMIN_DELETE_GROUP}/${selectedGroup}`);
    const allGroups = await getAllGroups();
    setAllGroups(allGroups);
    setSelectedGroup('');
  };

  const handleDeleteOnClick = async () => {
    setError(null);
    setButtonLoading((prevState) => ({ ...prevState, delete: true }));
    try {
      const { data: groupMemberCount } = await axios.get(
        `${USER_ADMIN_GROUP_MEMBERS}/${selectedGroup}`,
      );

      if (groupMemberCount.length === 0) {
        await deleteGroup();
      } else {
        setShowConfirmationModal(true);
        setUserCount(groupMemberCount.length);
      }
    } catch (err) {
      console.error(err);
    }
    setButtonLoading((prevState) => ({ ...prevState, delete: false }));
  };

  const handleNewGroupNameValueOnChange = (e) => {
    setError(null);
    const { value } = e.target;
    const error = !/^[a-zA-Z]+$/.test(value) && value.length > 0;
    setNewGroupNameValue({ value, error });
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  return (
    <Feature name={featureFlags.ACCESS_SUPER_ADMIN}>
      <UserAdminViewContainer title={'Add Group'}>
        {error && <AOAlert severity="error">{error}</AOAlert>}
        <AOCard>
          <Box display="flex">
            <Box mr={3}>
              <AOSelect
                multiple
                native
                value={[selectedGroup]}
                onChange={(e) => {
                  setSelectedGroup(e.target.value);
                }}
                inputProps={{
                  id: 'user-admin-add-group-select-all-groups',
                  style: { height: '300px' },
                }}>
                {allGroups.map((name) => (
                  <option id={`option-${name}`} key={name} value={name}>
                    {name}
                  </option>
                ))}
              </AOSelect>
            </Box>

            <Box>
              <Box mb={2}>
                <AOTextField
                  error={newGroupNameValue.error}
                  helperText="Use Only Letters (A-Z)"
                  id="add-group-input-name"
                  label="New Group Name"
                  onChange={handleNewGroupNameValueOnChange}
                  value={newGroupNameValue.value}
                />
              </Box>

              <Box display="flex">
                <AOButton
                  color="primary"
                  disabled={
                    newGroupNameValue.value.length === 0 ||
                    newGroupNameValue.error ||
                    buttonLoading.add
                  }
                  id="add-group-button-add"
                  loading={buttonLoading.add}
                  onClick={handleAddOnClick}
                  variant="contained">
                  Add
                </AOButton>
                <Box mr={1} />
                <AOButton
                  color="primary"
                  id="add-group-button-clear"
                  onClick={handleClearOnClick}
                  variant="outlined">
                  Clear
                </AOButton>
              </Box>

              <Box mt={12}>
                <Typography color="textSecondary">
                  Use this button to remove the Group selected on the left from the list.
                </Typography>
                <Box mt={2} mb={1}>
                  <AOButton
                    color="primary"
                    disabled={!selectedGroup || buttonLoading.delete}
                    id="add-group-button-delete"
                    loading={buttonLoading.delete}
                    onClick={handleDeleteOnClick}
                    variant="contained">
                    Delete
                  </AOButton>
                </Box>
                <AOLinkButton route={ROUTE_USER_ADMIN_GROUP_INFORMATION}>
                  Return to get group information
                </AOLinkButton>
              </Box>
            </Box>
          </Box>
        </AOCard>
      </UserAdminViewContainer>
      {showConfirmationModal && (
        <Modal
          open={showConfirmationModal}
          onClose={handleCloseConfirmationModal}
          aria-labelledby="add-group-modal-title"
          aria-describedby="add-group-modal-description"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Paper style={{ padding: `1rem` }}>
            <Box mb={2}>
              <Typography>{`There ${
                userCount === 1 ? 'is 1 user in the group' : `are ${userCount} users in this group`
              }. Are you sure you want to delete it?`}</Typography>
            </Box>
            <Box display="flex" justifyContent={'center'}>
              <AOButton
                color="primary"
                id="add-group-button-yes"
                onClick={async () => {
                  await deleteGroup();
                  handleCloseConfirmationModal();
                }}
                variant="contained">
                Yes
              </AOButton>
              <Box mr={1} />
              <AOButton
                color="primary"
                id="add-group-button-no"
                onClick={handleCloseConfirmationModal}
                variant="outlined">
                No
              </AOButton>
            </Box>
          </Paper>
        </Modal>
      )}
    </Feature>
  );
};

export default UserAdminAddGroup;
