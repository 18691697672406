import React from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import UnderwritingPersonalLinesSkeleton from './UnderwritingPersonalLinesSkeleton';

import useScreenDefinition from '../../hooks/useScreenDefinition';

const UnderwritingPersonalLines = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`UnderwritingPersonalLines`);
  const localStateMap = {};

  const callBackFunctions = {};

  if (loadingSd) return <UnderwritingPersonalLinesSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default UnderwritingPersonalLines;
