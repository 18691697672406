import React from 'react';
import _ from 'lodash';

import UserAdminViewContainer from './UserAdminViewContainer';
import { ScreenSmith } from '../ScreenSmith';
import UserAdminSkeleton from './UserAdminSkeleton';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import SearchUser from './views/SearchUser';

const LocalComponents = { SearchUser };

const UserAdminSearchUser = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`UserAdmSearchUser`);

  const localStateMap = {};

  const callBackFunctions = {};

  return loadingSd ? (
    <UserAdminSkeleton />
  ) : (
    <UserAdminViewContainer title={'Search for User'}>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={LocalComponents}
        />
      )}
    </UserAdminViewContainer>
  );
};

export default UserAdminSearchUser;
