// Generated with utilities/generator/generate-component.js
import { rem } from 'polished';

import { Box, styled } from '@material-ui/core';

const StyledUIShell = styled(Box)({
  backgroundColor: `#F2F3F4`,
  display: `flex`,
});

const StyledUIShellBody = styled(Box)({
  padding: `${rem(24)}`,
  width: `100%`,
});

export { StyledUIShell, StyledUIShellBody };
