import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cuid from 'cuid';
import moment from 'moment';
import { Button } from '@material-ui/core';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import { PLSWipSkeleton } from '../AOSkeleton';
import { AgentLookupModal } from '../AgentLookupModal';
import PLSWipExternal from './PLSWipExternal';

import { GET_PL_AUTO_COMPLETION_INSURED } from '../../constants/api';

import {
  API_PLS_WIP_BASE_URL,
  plWipLobMenuItemsData,
  getOptionLabel,
  filterOptions,
  handleAOAutocompleteOnBlur,
  handleAOAutocompleteOnInputChange,
  fetchPlWipAutoCompletion,
} from './PersonalLinesServices.api';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';

const LocalComponents = {
  AgentLookupModal,
  PLSWipExternal,
};

export const initialPlEntryStartDate = moment().subtract(20, 'days').format('MM-DD-YYYY');
export const initialPlWipEntryEndDate = moment().format('MM-DD-YYYY');
export let initialWipRequest;

const PLSWip = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`PLSWorkInProgressPanel`);

  const renderCell = (params) => {
    const handleClickOpenPolicy = () => {
      const selectedPolicyObject = params.row;
      let request_body = {
        Area: 'Personal Lines',
        DetailedContext: [
          {
            name: 'businessType',
            value: 'PL',
          },
          {
            name: 'quoteNumber',
            value: params.value,
          },
        ],
      };
      if (selectedPolicyObject.e1PQuote) {
        request_body.TypeOfRedirect = 'Wip E1P Resume Quote';
        request_body.DetailedContext.push({
          name: 'productLine',
          value: 'E1P',
        });
        return redirectUrlFunction(request_body, 'POST', null);
      } else {
        request_body.TypeOfRedirect = 'Wip PL Resume Quote';
        if (selectedPolicyObject.transID !== undefined) {
          request_body.DetailedContext.push(
            {
              name: 'id',
              value: selectedPolicyObject.transID,
            },
            {
              name: 'rowan',
              value: selectedPolicyObject.rowan,
            },
          );
        }
        if (selectedPolicyObject.system !== undefined) {
          if (selectedPolicyObject.system === 'OneShield') {
            request_body.DetailedContext.push({
              name: 'app',
              value: 'ONE',
            });
          } else {
            request_body.DetailedContext.push({
              name: 'app',
              value: 'MSA',
            });
            if (selectedPolicyObject.lobDesc !== undefined) {
              request_body.DetailedContext.push({
                name: 'lob',
                value: selectedPolicyObject.lobDesc,
              });
            }
          }
        }
        return redirectUrlFunction(
          request_body,
          'POST',
          null,
          setWipRedirectError,
          setIsWipRedirectErrorVisible,
        );
      }
    };
    return (
      <Button
        aria-label="policy/qt"
        onClick={() => {
          handleClickOpenPolicy();
        }}
        color="primary">
        {params.value}
      </Button>
    );
  };
  const plWipSearchResultsColumnsData = [
    {
      field: 'reference',
      headerName: 'Policy/QT/Ref #',
      editable: true,
      flex: 1,
      renderCell,
    },
    {
      field: 'insName',
      headerName: 'Named Insured/Description',
      flex: 1,
      editable: true,
    },
    {
      field: 'lob',
      headerName: 'LOB',
      flex: 1,
      editable: true,
    },
    {
      field: 'effDate',
      headerName: 'Effective Date',
      type: 'date',
      flex: 1,
      editable: true,
    },
    {
      field: 'entDate',
      headerName: 'Entered Date',
      type: 'date',
      flex: 1,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      editable: true,
    },
    {
      field: 'userID',
      headerName: 'User ID',
      flex: 1,
      editable: true,
    },
    {
      field: 'agentNum',
      headerName: 'Agent Code',
      type: 'number',
      flex: 1,
      editable: true,
    },
  ];

  // PL Wip State variables
  const [plWipSearchResultsColumns] = useState(plWipSearchResultsColumnsData);
  const [fetchingData, setFetchingData] = useState(false);
  const [plWipSearch, setPlWipSearch] = useState();
  const [plWipSearchLoading, setPlWipSearchLoading] = useState(true);
  const [plWipSearchError, setPlWipSearchError] = useState(null);
  const [plWipAgentCodeValue, setPlWipAgentCodeValue] = useState('');
  const [plWipLobMenuItems] = useState(plWipLobMenuItemsData);

  const [plWipSearchType, setPlWipSearchType] = useState('enterDate');
  const [plWipSearchTypeValue, setPlWipSearchTypeValue] = useState('');
  const [plWipEntryStartDate, setPlWipEntryStartDate] = useState(initialPlEntryStartDate);
  const [plWipEntryEndDate, setPlWipEntryEndDate] = useState(initialPlWipEntryEndDate);

  const [plWipEffStartDate, setPlWipEffStartDate] = useState(moment().format('MM-DD-YYYY'));
  const [plWipEffEndDate, setPlWipEffEndDate] = useState(
    moment().add(7, 'days').format('MM-DD-YYYY'),
  );
  const [isAgentLookupModalOpen, setIsAgentLookupModalOpen] = useState(false);

  const [plWipLob, setPlWipLob] = useState('ALL');
  const [plWipLobIsDisabled, setPlWipLobIsDisabled] = useState(false);
  const [isWipRedirectErrorVisible, setIsWipRedirectErrorVisible] = useState(false);
  const [isEnterSearchCriteriaAlertVisible, setIsEnterSearchCriteriaAlertVisible] = useState(true);
  const [wipRedirectError, setWipRedirectError] = useState(false);
  const [insuredNameOptions, setInsuredNameOptions] = useState([]);
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    insuredName: { value: '' },
  });

  // --- Event Handlers for PL WIP ---
  const handleChangePlWipSearchType = (event) => {
    const lob = event.target.value;
    setPlWipLobIsDisabled(false);
    setPlWipSearchTypeValue('');
    setPlWipLob('ALL');
    setPlWipAgentCodeValue('');
    setPlWipSearchType(lob);
    setIsWipRedirectErrorVisible(false);
  };
  const handleChangePlWipSearchTypeValue = (event) => {
    setPlWipSearchTypeValue(event.target.value);
  };
  const handleChangePlWipEntryStartDate = (date) => {
    setPlWipEntryStartDate(moment(date).format('MM-DD-YYYY'));
  };
  const handleChangePlWipEntryEndDate = (date) => {
    setPlWipEntryEndDate(moment(date).format('MM-DD-YYYY'));
  };
  const handleChangePlWipEffStartDate = (date) => {
    setPlWipEffStartDate(moment(date).format('MM-DD-YYYY'));
  };
  const handleChangePlWipEffEndDate = (date) => {
    setPlWipEffEndDate(moment(date).format('MM-DD-YYYY'));
  };
  const handleChangePlWipLob = (event) => {
    setPlWipLob(event.target.value);
  };
  const handleChangePlWipAgentCodeValue = (event) => {
    setPlWipAgentCodeValue(event.target.value);
  };

  const buildPlWipSearchEndpoint = () => {
    let endpoint;
    // agencyNumber
    let agentCode = plWipAgentCodeValue ? `&agency=${plWipAgentCodeValue}` : '';
    let lob = plWipLob ? `&lob=${plWipLob}` : '';
    switch (plWipSearchType) {
      case 'insuredName': {
        endpoint = `&searchCriteria=insuredName&searchValue=${plWipSearchTypeValue}${agentCode}${lob}`;
        break;
      }
      case 'quote': {
        endpoint = `&searchCriteria=quote&searchValue=${plWipSearchTypeValue}${agentCode}${lob}`;
        break;
      }
      case 'policyNumber': {
        endpoint = `&searchCriteria=policyNumber&searchValue=${plWipSearchTypeValue}${agentCode}${lob}`;
        break;
      }
      case 'effectiveDate': {
        endpoint = `&searchCriteria=effectiveDate&startDate=${plWipEffStartDate}&endDate=${plWipEffEndDate}${lob}${agentCode}`;
        break;
      }
      default: {
        endpoint = `&searchCriteria=enterDate&startDate=${plWipEntryStartDate}&endDate=${plWipEntryEndDate}${lob}${agentCode}`;
        break;
      }
    }
    return endpoint;
  };

  const submitPlWip = () => {
    setFetchingData(true);
    setPlWipSearch();
    setPlWipSearchError(null);
    setIsWipRedirectErrorVisible(false);
    setIsEnterSearchCriteriaAlertVisible(false);
    setPlWipSearchLoading(true);
    const PL_WIP_ENDPOINT = buildPlWipSearchEndpoint();
    axios(`${API_PLS_WIP_BASE_URL}${PL_WIP_ENDPOINT}`)
      .then((results) => {
        setPlWipSearchLoading(false);
        const WorkInProgress = results.data.WorkInProgress;
        const resWithId = WorkInProgress.map((el) => {
          return { id: cuid(), ...el };
        });
        setPlWipSearch(resWithId);
        setFetchingData(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.message === 'Network Error') {
          setWipRedirectError(
            'Too many results to display, modify search criteria to reduce the number of results.',
          );
          setIsWipRedirectErrorVisible(true);
        }
        setPlWipSearchError(error);
      })
      .finally(() => {
        setFetchingData(false);
      });
  };

  const resetPlWip = () => {
    setPlWipEntryStartDate(initialPlEntryStartDate);
    setPlWipEntryEndDate(initialPlWipEntryEndDate);
    setPlWipSearchType('enterDate');
    setPlWipAgentCodeValue('');
    setPlWipLob('ALL');
    setPlWipLobIsDisabled(false);
    setPlWipSearchTypeValue('');
    setIsWipRedirectErrorVisible(false);
    initialWipRequest(initialPlEntryStartDate, initialPlWipEntryEndDate);
  };

  const plState = {
    insuredNameOptions,
    insuredNameInputValue: autocompleteInputValues.insuredName.value,
    fetchingData,
    plWipAgentCodeValue,
    plWipEffEndDate,
    plWipEffStartDate,
    plWipEntryEndDate,
    plWipEntryStartDate,
    plWipLob,
    plWipLobIsDisabled,
    plWipLobMenuItems,
    plWipSearch,
    plWipSearchError,
    plWipSearchLoading,
    plWipSearchResultsColumns,
    plWipSearchType,
    plWipSearchTypeValue,
    isAgentLookupModalOpen,
    wipRedirectError,
    isWipRedirectErrorVisible,
    isEnterSearchCriteriaAlertVisible,
  };

  const callBackFunctions = {
    handleChangePlWipAgentCodeValue,
    handleChangePlWipEffEndDate,
    handleChangePlWipEffStartDate,
    handleChangePlWipEntryEndDate,
    handleChangePlWipEntryStartDate,
    handleChangePlWipLob,
    handleChangePlWipSearchType,
    handleChangePlWipSearchTypeValue,
    redirectUrlFunction,
    submitPlWip,
    resetPlWip,
    handleAgentLookupModalOpen: () => setIsAgentLookupModalOpen(true),
    setIsAgentLookupModalOpen,
    setPlWipAgentCodeValue,
    getOptionLabel,
    filterOptions,
    handleInsuredNameOnBlur: () =>
      handleAOAutocompleteOnBlur(
        'insuredName',
        plWipSearchTypeValue,
        setPlWipSearchTypeValue,
        autocompleteInputValues,
      ),
    handleInsuredNameOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'insuredName',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        setPlWipSearchTypeValue,
      ),
    fetchPlWipAutoCompletion: (e) => fetchPlWipAutoCompletion(e),
  };

  initialWipRequest = (initialPlEntryStartDate, initialPlWipEntryEndDate) => {
    setFetchingData(true);
    axios(
      `${API_PLS_WIP_BASE_URL}&searchCriteria=enterDate&startDate=${initialPlEntryStartDate}&endDate=${initialPlWipEntryEndDate}`,
    )
      .then((response) => {
        const {
          data: { WorkInProgress },
        } = response;
        const resWithId = WorkInProgress.map((el) => {
          return { id: cuid(), ...el };
        });
        setPlWipSearch(resWithId);
        setPlWipSearchLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.message === 'Network Error') {
          setWipRedirectError(
            'Too many results to display, modify search criteria to reduce the number of results.',
          );
          setIsWipRedirectErrorVisible(true);
        }
        setPlWipSearchError(error);
      })
      .finally(() => {
        setFetchingData(false);
      });
  };

  useEffect(async () => {
    if (autocompleteInputValues.insuredName.value.length >= 3) {
      setIsWipRedirectErrorVisible(false);
      setWipRedirectError('');
      const suggestions = await fetchPlWipAutoCompletion(
        autocompleteInputValues.insuredName.value,
        GET_PL_AUTO_COMPLETION_INSURED,
      ).catch((err) => {
        console.error(err);
        if (err.response && err.response.data.status == 400) {
          setIsWipRedirectErrorVisible(true);
          setWipRedirectError(err.response.data.htmlMessage);
        }
      });
      setInsuredNameOptions(suggestions);
    } else {
      setInsuredNameOptions([]);
    }
  }, [autocompleteInputValues.insuredName.value]);

  if (loadingSd) return <PLSWipSkeleton />;

  return (
    !_.isNil(UiDefinitionsData) && (
      <ScreenSmith
        componentMap={LocalComponents}
        definition={UiDefinitionsData}
        functionMap={callBackFunctions}
        stateMap={plState}
      />
    )
  );
};

export default PLSWip;
