import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { ScreenSmith } from '../ScreenSmith';
import { CardTabSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';

import CLSPolicyChangeView from './CLSPolicyChangeView';
import CLSNews from './CLSNews';
import CLSWip from './CLSWip';
import CLSQuoteNewBusiness from './CLSQuoteNewBusiness';
import CLSPolicyInquiry from './CLSPolicyInquiry';

import { StyledCommercialLinesServices } from './CommercialLinesServices.styled';

import { handleRoute } from '../CommonUtilityServices/HandleRouteService';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import { handleTabChange } from '../CommonUtilityServices/EventHandlerService';

import { clPolicyChangeViewSearchTypeMenuItemsData } from './CommercialLinesServices.api';

const PanelComponents = {
  CLSNews,
  CLSQuoteNewBusiness,
  CLSWip,
  CLSPolicyChangeView,
  CLSPolicyInquiry,
};

const CommercialLinesServices = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('CLSHome');
  const [isClsRedirectErrorVisible, setIsClsRedirectErrorVisible] = useState(false);
  const [clsRedirectError, setClsRedirectError] = useState(false);

  const clsCoastalGuidelinesToolLink = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'Coastal Guidelines',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const clsNewBusinessQuickLink = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'New Business',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const clsNewQuoteQuickLink = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'Quick Quote',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const clsMainStreetStationQuickLink = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'Main Street Station',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const clsMainStreetPersonalPlusQuickLink = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'MSP+',
      DetailedContext: [
        {
          name: 'effectiveDate',
          value: moment().format('MM/DD/yyyy'),
        },
      ],
    };
    return redirect(request_body);
  };

  const clsMSEPolicyAdministrationQuickLink = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'MSE Policy Administration',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const clsMSEAgencySolutionCenterQuickLink = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'MSE Agency Solution Center',
      DetailedContext: [
        {
          name: 'system',
          value: 'SBSS',
        },
      ],
    };
    return redirect(request_body);
  };

  const commercialLinesPcr = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'PCR',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const underwritingCloud = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'Coastal Guidelines',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const redirect = (request_body) => {
    return redirectUrlFunction(
      request_body,
      'POST',
      null,
      setClsRedirectError,
      setIsClsRedirectErrorVisible,
    );
  };

  const [clPolicyChangeViewSearchTypeMenuItems] = useState(
    clPolicyChangeViewSearchTypeMenuItemsData,
  );

  const [tabValue, setTabValue] = React.useState(1);

  // Policy Document State
  const [clPolicyDocumentsColData] = useState([
    {
      field: 'documentType',
      headerName: 'Document Type',
      width: `25%`,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: true,
      flex: 2,
    },
    {
      field: 'documentDate',
      headerName: 'Date',
      type: 'date',
      sortable: true,
      flex: 1,
    },
    {
      field: 'documentLink',
      headerName: 'Document Link',
      sortable: true,
      flex: 1,
      //renderCell, // renderCell : renderCell which is function to render custom content to download pdf
    },
  ]);
  const [clPolicyDocumentNumber, setClPolicyDocumentNumber] = useState('');

  // --- Event Handlers for PL Policy Documents ---
  const handleChangePlPolicyDocumentNumber = useCallback((event) => {
    setClPolicyDocumentNumber(event.target.value);
  });

  const clState = {
    clPolicyChangeViewSearchTypeMenuItems,
    clPolicyDocumentNumber,
    clPolicyDocumentsColData,
    tabValue,
    isClsRedirectErrorVisible,
    clsRedirectError,
  };

  const callBackFunctions = {
    clsCoastalGuidelinesToolLink,
    handleChangePlPolicyDocumentNumber,
    handleRoute,
    handleTabChange: (e, newValue) => handleTabChange(e, newValue, setTabValue),
    clsNewBusinessQuickLink,
    clsNewQuoteQuickLink,
    clsMainStreetStationQuickLink,
    clsMainStreetPersonalPlusQuickLink,
    commercialLinesPcr,
    underwritingCloud,
    clsMSEPolicyAdministrationQuickLink,
    clsMSEAgencySolutionCenterQuickLink,
  };

  if (loadingSd) return <CardTabSkeleton />;

  return (
    <StyledCommercialLinesServices>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={PanelComponents}
          functionMap={callBackFunctions}
          stateMap={clState}
        />
      )}
    </StyledCommercialLinesServices>
  );
};

export default CommercialLinesServices;
