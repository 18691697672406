import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import cuid from 'cuid';
import Modal from '@material-ui/core/Modal';
import { useHistory } from 'react-router-dom';

import { ScreenSmith } from '../ScreenSmith';
import ClaimsInquiryDetailsSkeleton from './ClaimsInquiryDetailsSkeleton';
import { AOCircularProgress } from '../AOCircularProgress';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { GET_CLAIMS_DETAILS } from '../../constants/api';
import { BillingInquiryDetailsPDFButton } from '../BillingInquiryDetails/BillingInquiryDetails.components';
import { transactionHistoryColumns, handleExportToPDFOnClick } from './ClaimsInquiryDetails.api';
import { StyledClaimsInquiryDetailsModalBodyContainer } from './ClaimsInquiryDetails.styled';
import {
  GET_BILLING_EXACT_SEARCH,
  GET_BILLING_SEARCH_PAGE_COUNT,
  POLICY_NUMBER_URL,
} from '../../constants/api';
import { ROUTE_ACCOUNT_DETAIL, ROUTE_BILLING_SERVICES } from '../../constants/routes';
import AppDataContext from '../../contexts/appData.context';

const LocalComponents = { AOCircularProgress, BillingInquiryDetailsPDFButton };

const ClaimsInquiryDetails = ({ selectedClaim, handleCloseClaimsInquiryDetails }) => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`ClaimsInquiryDetails`);

  const [loading, setLoading] = useState(true);
  const [claimsDetails, setClaimsDetails] = useState();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);

  const { setPreviousPageName } = useContext(AppDataContext);
  const history = useHistory();

  useEffect(async () => {
    if (selectedClaim) {
      try {
        const { policyNumber, occurrenceNumber } = selectedClaim;
        const getClaimsDetailUrl = `${GET_CLAIMS_DETAILS}/${policyNumber}/${occurrenceNumber}`;
        const details = await axios.get(getClaimsDetailUrl);
        setClaimsDetails(details.data);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  }, [selectedClaim]);

  const adjusterToEmail = claimsDetails?.claimSummary?.adjusterEmail;

  const localStateMap = {
    loading,
    ...claimsDetails?.claimSummary,
    adjusterEmailHref: `mailto:${adjusterToEmail}`,
    transactionHistoryColumns,
    claimsDetailsTransactionHistory:
      claimsDetails?.claimsDetailsTransactionHistory?.map((el) => ({
        id: cuid(),
        ...el,
      })) ?? [],
    adjusterNotAvailable: !claimsDetails?.claimSummary?.adjusterFullname,
    adjusterPhoneNumber:
      claimsDetails?.claimSummary?.adjusterFullname &&
      claimsDetails?.claimSummary?.adjusterPhoneNumber,
    adjusterEmail:
      claimsDetails?.claimSummary?.adjusterFullname && claimsDetails?.claimSummary?.adjusterEmail,
    showClaimVehicleDetails:
      claimsDetails?.claimSummary?.lobDesc === 'Auto' &&
      claimsDetails?.claimSummary?.vehicleYear != '-2',
    showAdjusterMissingMessage:
      !claimsDetails?.claimSummary?.adjusterFullname ||
      !claimsDetails?.claimSummary?.adjusterPhoneNumber,
    loadingPdf,
    isDownloadingPdfErrorVisible,
    downloadingPdfError,
  };

  const callBackFunctions = {
    handleCloseClaimsInquiryDetails,
    handleExportToPDFOnClick: () =>
      handleExportToPDFOnClick(
        selectedClaim.policyNumber,
        selectedClaim.occurrenceNumber,
        setLoadingPdf,
        setDownloadingPdfError,
        setIsDownloadingPdfErrorVisible,
      ),
    handleOnClose: () => handleOnClose(),
    handleOnClickToBilling: async () => {
      setPreviousPageName('Claims Home');
      const policyNumber = selectedClaim.policyNumber;
      const count = await axios.get(
        `${GET_BILLING_SEARCH_PAGE_COUNT}/exact${POLICY_NUMBER_URL}/${policyNumber}`,
      );

      if (count.data === 1) {
        const results = await axios.get(
          `${GET_BILLING_EXACT_SEARCH}${POLICY_NUMBER_URL}/${policyNumber}/1`,
        );
        history.push(`${ROUTE_ACCOUNT_DETAIL}/${results.data[0].accountNumber}`);
      } else {
        history.push(ROUTE_BILLING_SERVICES);
      }
    },
  };

  const handleOnClose = () => {
    handleCloseClaimsInquiryDetails();
    setLoading(true);
  };

  return loadingSd ? (
    <ClaimsInquiryDetailsSkeleton />
  ) : (
    <Modal
      open={selectedClaim}
      onClose={() => handleOnClose()}
      aria-labelledby="claims-inquiry-details-title"
      aria-describedby="claims-inquiry-details-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <StyledClaimsInquiryDetailsModalBodyContainer>
        {!_.isNil(UiDefinitionsData) && (
          <ScreenSmith
            definition={UiDefinitionsData}
            functionMap={callBackFunctions}
            stateMap={localStateMap}
            componentMap={LocalComponents}
          />
        )}
      </StyledClaimsInquiryDetailsModalBodyContainer>
    </Modal>
  );
};

ClaimsInquiryDetails.propTypes = {
  selectedClaim: PropTypes.object,
  handleCloseClaimsInquiryDetails: PropTypes.func,
};

export default ClaimsInquiryDetails;
