import React, { useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import { GET_INQUIRE_MSS_POLICY } from '../../constants/api';
import { GET_INQUIRE_MSE_POLICY } from '../../constants/api';

import { ScreenSmith } from '../ScreenSmith';
import { CLSPolicyInquirySkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';

import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';

export const sendGetPolicyInquiry = async (
  _request_body,
  setErrorGetPolicyInquiry,
  setIsGetPolicyInquiryAlertVisible,
  setPolicyInquiryLoading,
) => {
  let getPolicyInquiry;
  try {
    getPolicyInquiry = await axios.post(`${GET_INQUIRE_MSS_POLICY}`, _request_body);
  } catch (err) {
    // Handle Error Here
    setErrorGetPolicyInquiry(err.message);
    setIsGetPolicyInquiryAlertVisible(true);
    setPolicyInquiryLoading(false);
    getPolicyInquiry = err;
  }
  return getPolicyInquiry;
};

const CLSPolicyInquiry = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('CLSPolicyInquiryPanel');
  const [selectedPolicySystem, setselectedPolicySystem] = useState('');
  const [selectedPolicyNumber, setselectedPolicyNumber] = useState('');
  const [errorGetPolicyInquiry, setErrorGetPolicyInquiry] = useState(null);
  const [isGetPolicyInquiryAlertVisible, setIsGetPolicyInquiryAlertVisible] = useState(false);
  const [policyNumberNotFound, setPolicyNumberNotFound] = useState(null);
  const [isPolicyNumberNotFoundAlertVisible, setIsPolicyNumberNotFoundAlertVisible] =
    useState(false);
  const [policyInquiryLoading, setPolicyInquiryLoading] = useState(false);
  const [isPolicyInquiryRedirectErrorVisible, setIsPolicyInquiryRedirectErrorVisible] =
    useState(false);
  const [policyInquiryRedirectError, setPolicyInquiryRedirectError] = useState(false);

  const handleChangeSelectedPolicySystem = (event) => {
    setselectedPolicySystem(event.target.value);
  };

  const handleChangeSelectedPolicyNumber = (event) => {
    setselectedPolicyNumber(event.target.value);
  };

  const resetAlerts = () => {
    setIsGetPolicyInquiryAlertVisible(false);
    setPolicyNumberNotFound(null);
    setIsPolicyNumberNotFoundAlertVisible(false);
    setIsPolicyInquiryRedirectErrorVisible(false);
  };

  const getMSSPolicyInfoAndRedirect = () => {
    const request_body_for_policy_inquiry = {
      // inquiryDate: viewPolicyAsOfDate,
      inquiryType: 'Policy Inquiry',
      policyNumber: selectedPolicyNumber,
    };
    // Call Proxy Policy Inquiry to get data for request body
    sendGetPolicyInquiry(
      request_body_for_policy_inquiry,
      setErrorGetPolicyInquiry,
      setIsGetPolicyInquiryAlertVisible,
      setPolicyInquiryLoading,
    ).then((policyInquiryPayload) => {
      // reset laoding filter
      setPolicyInquiryLoading(false);
      // if data is undefined (bad request) break out
      if (!_.isNil(policyInquiryPayload.data)) {
        // if 200 and policyNumber is null or undefined
        if (
          _.isNil(policyInquiryPayload.data.policyNumber) ||
          _.isNil(policyInquiryPayload.data.acctType)
        ) {
          setPolicyNumberNotFound(policyInquiryPayload.data.details);
          setIsPolicyNumberNotFoundAlertVisible(true);
        } else {
          const today = moment().format('MM/DD/YYYY');
          const redirect_request_body = {
            Area: 'Commercial Lines',
            TypeOfRedirect: 'MSS Policy Inquiry',
            DetailedContext: [
              {
                name: 'transactionId',
                value: policyInquiryPayload.data.policyInquiryInfo.systemAssignId,
              },
              {
                name: 'accountType',
                value: policyInquiryPayload.data.acctType,
              },
              {
                name: 'effectiveDate',
                value: today,
              },
            ],
          };

          redirectUrlFunction(
            redirect_request_body,
            'POST',
            null,
            setPolicyInquiryRedirectError,
            setIsPolicyInquiryRedirectErrorVisible,
          );
        }
      }
    });
  };

  const sendGetMSEMSCPlusPolicy = async () => {
    let policyNumber;
    try {
      policyNumber = await axios.get(`${GET_INQUIRE_MSE_POLICY}/${selectedPolicyNumber}`);
    } catch (err) {
      setErrorGetPolicyInquiry(err.message);
      setIsGetPolicyInquiryAlertVisible(true);
      setPolicyInquiryLoading(false);
      policyNumber = err;
    }
    return policyNumber;
  };

  const getMSEMSCPlusPolicyInfoAndRedirect = (policy_system) => {
    sendGetMSEMSCPlusPolicy().then((clmsePolicyPayload) => {
      // check to make sure there is data
      if (
        !_.isNil(clmsePolicyPayload) &&
        !_.isNil(clmsePolicyPayload.data) &&
        clmsePolicyPayload.data.length > 0
      ) {
        // login to sbss
        const redirect_request_body = {
          Area: 'Commercial Lines',
          TypeOfRedirect: 'MSE Policy Inquiry',
          DetailedContext: [
            {
              name: 'system',
              value: 'SBSS',
            },
            {
              name: 'policyNumber',
              value: clmsePolicyPayload.data[0].policyNumber,
            },
            {
              name: 'agencyNumber',
              value: clmsePolicyPayload.data[0].agencyId,
            },
            {
              name: 'path',
              value: 'details',
            },
          ],
        };

        redirectUrlFunction(
          redirect_request_body,
          'POST',
          null,
          setPolicyInquiryRedirectError,
          setIsPolicyInquiryRedirectErrorVisible,
        );
        setPolicyInquiryLoading(false);
      } else {
        // set warning message
        if (policy_system == 'policy-inquiry-main-street-express') {
          setPolicyNumberNotFound(
            'Policy is not available in Main Street Express. Please modify your search and try again.',
          );
        } else {
          setPolicyNumberNotFound(
            'Policy is not available in Main Street Commercial+. Please modify your search and try again.',
          );
        }
        setIsPolicyNumberNotFoundAlertVisible(true);
        setPolicyInquiryLoading(false);
      }
    });
  };

  const handleClickSearch = () => {
    // reset any alerts
    resetAlerts();
    setPolicyInquiryLoading(true);
    if (selectedPolicySystem === 'policy-inquiry-main-street-station') {
      getMSSPolicyInfoAndRedirect();
    } else if (selectedPolicySystem === 'policy-inquiry-main-street-express') {
      getMSEMSCPlusPolicyInfoAndRedirect(selectedPolicySystem);
    } else if (selectedPolicySystem === 'policy-inquiry-main-street-commercial+') {
      getMSEMSCPlusPolicyInfoAndRedirect(selectedPolicySystem);
    } else {
      setPolicyNumberNotFound('Please select a Policy System to continue with a search.');
      setIsPolicyNumberNotFoundAlertVisible(true);
      setPolicyInquiryLoading(false);
    }
  };

  const localStateMap = {
    selectedPolicySystem,
    selectedPolicyNumber,
    errorGetPolicyInquiry,
    isGetPolicyInquiryAlertVisible,
    isPolicyNumberNotFoundAlertVisible,
    policyInquiryLoading,
    policyNumberNotFound,
    policyInquiryRedirectError,
    isPolicyInquiryRedirectErrorVisible,
  };

  const callBackFunctions = {
    handleChangeSelectedPolicySystem,
    handleChangeSelectedPolicyNumber,
    handleClickSearch,
  };

  if (loadingSd) return <CLSPolicyInquirySkeleton data-testid="skeleton" />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};
export default CLSPolicyInquiry;
