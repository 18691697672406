import axios from 'axios';
import moment from 'moment';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { GET_AGENCY_SUGGESTIONS } from '../../constants/api';

const fetchAgencyNameSuggestions = async (searchQuery) => {
  const tAReportsUrl = `${GET_AGENCY_SUGGESTIONS}?searchValue=${searchQuery}`;
  const tAReportsData = await axios.get(`${tAReportsUrl}`);
  const tAReportsSuggestion = tAReportsData.data;
  return tAReportsSuggestion;
};

const handleAOAutocompleteOnBlur = (property, formData, setFormData, autocompleteInputValues) => {
  if (formData[property] !== autocompleteInputValues[property].value) {
    setFormData({ ...formData, [property]: autocompleteInputValues[property].value });
  }
};

const handleAOAutocompleteOnChange = (property, e, value, formData, setFormData) => {
  setFormData({ ...formData, [property]: value });
};

const handleAOAutocompleteOnInputChange = (
  property,
  e,
  value,
  autocompleteInputValues,
  setAutocompleteInputValues,
  debouncedState,
  setDebouncedState,
) => {
  setAutocompleteInputValues({
    ...autocompleteInputValues,
    [property]: {
      ...autocompleteInputValues[property],
      value,
      invalidText: getInvalidText(property, value),
    },
  });
  setDebouncedState({ ...debouncedState, [property]: { ...debouncedState[property], value } });
};

const handleOnChange = (e, _formData, _setFormData) => {
  const {
    target: { value, name },
  } = e;
  _setFormData({ ..._formData, [`${name}`]: value });
};

const handleOnChangeCheckbox = (e, _setFormData) => {
  const {
    target: { checked, name },
  } = e;
  _setFormData((prevValue) => ({ ...prevValue, [`${name}`]: checked }));
};

const getInvalidText = (property, value) => {
  const trimmedValue = value.trim();
  const trimmedValueLength = trimmedValue.length;
  if (!trimmedValueLength) return '';
  switch (property) {
    case 'policyNumber':
      return trimmedValueLength >= 8 && trimmedValueLength <= 25
        ? ''
        : 'Policy number is invalid, please try again';
    case 'accountNumber':
      return trimmedValueLength >= 8 && trimmedValueLength <= 15
        ? ''
        : 'When filtering on Account Number, please enter 8 to 15 alphanumeric characters with dash';
    case 'partialInsuredName':
      return trimmedValueLength >= 4 ? '' : 'Please enter a minimum of 3 characters';
    default:
      return '';
  }
};

const handleTabChange = (event, _newValue, _setTabValue) => {
  _setTabValue(_newValue);
};

const getOptionLabel = (option) => option.value ?? option;

const filterOptions = createFilterOptions();

const setDateFormData = (_date, _formData, _setFormData, _datePropertyName) => {
  const newDate = moment(_date, 'MM/DD/YYYY');
  const formattedDate = moment(newDate).format('MM/DD/YYYY');
  _setFormData({ ..._formData, [`${_datePropertyName}`]: _date ? formattedDate : null });
};

const handleFromDateOnChange = (_date, _formData, _setFormData, _name) => {
  const datePropertyName = _name ? _name : 'fromDate';
  setDateFormData(_date, _formData, _setFormData, datePropertyName);
};

const handleToDateOnChange = (_date, _formData, _setFormData, _name) => {
  const datePropertyName = _name ? _name : 'toDate';
  setDateFormData(_date, _formData, _setFormData, datePropertyName);
};

export {
  fetchAgencyNameSuggestions,
  handleAOAutocompleteOnBlur,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleOnChange,
  handleOnChangeCheckbox,
  handleTabChange,
  getOptionLabel,
  filterOptions,
  handleFromDateOnChange,
  handleToDateOnChange,
  getInvalidText,
};
