import { rem } from 'polished';

import { Box, styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const StyledAOTransferList = styled(Box)(({ theme }) => ({
  padding: rem(theme.spacing(0)),

  label: {
    color: 'red',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'start',
  },
  paper: {
    width: rem(400),
    height: rem(300),
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  topLabel: {
    fontSize: '0.85rem',
    lineHeight: rem('32px'),
  },
  label: {
    textAlign: 'center',
    fontSize: '0.75rem',
    margin: theme.spacing(1, 0),
  },
}));

export { StyledAOTransferList, useStyles };
