import React from 'react';
import PropTypes from 'prop-types';

import { Header } from '../Header';

import { Footer } from '../Footer';

import { StyledUIShell, StyledUIShellBody } from './UIShell.styled';

const UIShell = ({ children }) => {
  return (
    <>
      <Header />
      <StyledUIShell>
        <StyledUIShellBody>{children}</StyledUIShellBody>
      </StyledUIShell>
      <Footer />
    </>
  );
};

UIShell.propTypes = {
  children: PropTypes.node,
};

export default UIShell;
