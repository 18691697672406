import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import axios from 'axios';

import { Feature } from '../Feature';
import featureFlags from '../../constants/featureFlags';
import UserAdminViewContainer from './UserAdminViewContainer';
import { AOCard } from '../AOCard';
import { AOTextField } from '../AOTextField';
import { AOButton } from '../AOButton';
import { handleOnChange } from '../../components/CommonUtilityServices/EventHandlerService';
import { AODataGrid } from '../AODataGrid';
import { ADMIN_CHANGE_HISTORY_SEARCH } from '../../constants/api';

const defaultFormData = { userName: '', adminName: '' };

const changeHistoryColumns = [
  {
    field: 'index',
    width: 75,
  },
  {
    field: 'userName',
    headerName: 'User Name',
    width: 250,
  },
  {
    field: 'adminName',
    headerName: 'Admin',
    width: 250,
  },
  {
    field: 'action',
    headerName: 'Change',
    width: 350,
  },
  {
    field: 'item',
    headerName: 'Item',
    width: 275,
  },
  {
    field: 'changeDateTime',
    headerName: 'Date',
    width: 200,
  },
];

const UserAdminViewChangeHistory = () => {
  const [formData, setFormData] = useState(defaultFormData);
  const [changeHistoryRows, setChangeHistoryRows] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [searchButtonLoading, setSearchButtonLoading] = useState(false);

  const handleClearOnClick = () => {
    setFormData(defaultFormData);
    setChangeHistoryRows(null);
  };

  const handleSearchOnClick = async () => {
    setSearchButtonLoading(true);
    setChangeHistoryRows(null);
    try {
      const searchUrl = `${ADMIN_CHANGE_HISTORY_SEARCH}?userName=${formData.userName}&adminName=${formData.adminName}`;
      const { data } = await axios.get(searchUrl);
      setChangeHistoryRows(
        data.map((row, index) => ({ ...row, id: row.auditID, index: index + 1 })),
      );
    } catch (error) {
      console.error(error);
    }
    setSearchButtonLoading(false);
  };

  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);

  return (
    <Feature name={featureFlags.ACCESS_USER_ADMIN}>
      <UserAdminViewContainer title={'View Change History'}>
        <AOCard>
          <Box mb={2}>
            <AOTextField
              id="change-history-input-user-name"
              label="User Name"
              name="userName"
              onChange={(e) => handleOnChange(e, formData, setFormData)}
              placeholder="Enter user name"
              value={formData.userName}
            />
          </Box>

          <Box mb={2}>
            <AOTextField
              id="change-history-input-admin"
              label="Admin"
              name="adminName"
              onChange={(e) => handleOnChange(e, formData, setFormData)}
              placeholder="Enter admin"
              value={formData.adminName}
            />
          </Box>

          <Box display="flex">
            <AOButton
              color="primary"
              id="change-history-button-search"
              loading={searchButtonLoading}
              onClick={handleSearchOnClick}
              variant="contained">
              Search
            </AOButton>
            <Box mr={1} />
            <AOButton
              color="primary"
              id="change-history-button-clear"
              onClick={handleClearOnClick}
              variant="outlined">
              Clear
            </AOButton>
          </Box>

          {changeHistoryRows && (
            <Box mt={3}>
              <AODataGrid
                autoHeight
                columns={changeHistoryColumns}
                onPageSizeChange={handlePageSizeChange}
                pageSize={pageSize}
                rows={changeHistoryRows}
                rowsPerPageOptions={[25, 50, 75, 100]}
                noResultsMessage="No history found matching your criteria."
              />
            </Box>
          )}
        </AOCard>
      </UserAdminViewContainer>
    </Feature>
  );
};

export default UserAdminViewChangeHistory;
