import React from 'react';
import cuid from 'cuid';
import { rem } from 'polished';
import {
  Grid,
  Box,
  Typography,
  Link,
  Divider,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import { StyledFooter, StyledFooterMain, StyledFooterFoot } from './Footer.styled';

import MSATrustedChoiceFooterLogo from '../../images/msa-trusted-choice-footer-logo.svg';

const Footer = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  let date = new Date().getFullYear();
  const footLinks = [
    {
      name: 'Terms and Conditions',
      route: 'https://msainsurance.com/sites/default/files/Terms_and_Conditions.pdf',
      meta: null,
    },
    {
      name: 'Privacy',
      route: 'https://msainsurance.com/privacy?utm_source=?ao',
      meta: null,
    },
    {
      name: 'Texting Terms and Conditions',
      route: 'https://msainsurance.com/texting-terms?utm_source=?ao',
      meta: null,
    },
  ];
  return (
    <StyledFooter>
      <StyledFooterMain>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box mb={`${rem(32)}`} width={200}>
              <Box component="img" src={MSATrustedChoiceFooterLogo} alt="MSA Logo" />
            </Box>
            <Box mb={`${rem(32)}`}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body1" color="textSecondary">
                  Customer Service: (877) 927-5672
                </Typography>
              </Box>

              <Typography variant="body1" color="textSecondary">
                Fax: (866) 420-8032
              </Typography>
              <Box display="flex" justifyContent="flex-start">
                <Typography>
                  <Link href="mailto:customer@msagroup.com" underline="hover" color="secondary">
                    customer@msagroup.com
                  </Link>
                </Typography>
              </Box>
            </Box>
            <Box mb={`${rem(32)}`}>
              <Typography variant="body1" color="textSecondary">
                Claims Customer Care:
              </Typography>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body1" color="textSecondary">
                  {' '}
                  (877) 425-2467
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-start">
                <Typography>
                  <Link href="mailto:MSAFNOL@afics.com" underline="hover" color="secondary">
                    MSAFNOL@afics.com
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="flex-end">
              <Box>
                <Box mb={1.25}>
                  <Typography variant="subtitle1">
                    <Box fontFamily="Montserrat" fontWeight={500}>
                      We welcome your suggestions
                    </Box>
                  </Typography>
                </Box>
                <Box sx={{ width: `${isSmall ? '100%' : rem(250)}` }} mb={`${rem(32)}`}>
                  <Typography
                    variant="body1"
                    align={isSmall ? 'initial' : 'right'}
                    color="textSecondary">
                    We appreciate your suggestions or general comments regarding our Agents Only
                    website
                  </Typography>
                </Box>
                <Box display="flex" justifyContent={isSmall ? 'flex-start' : 'flex-end'} mb={1}>
                  <Typography>
                    <Link
                      href="mailto:suggestions@msagroup.com"
                      underline="hover"
                      color="secondary">
                      suggestions@msagroup.com
                    </Link>
                  </Typography>
                </Box>

                <Box display="flex" justifyContent={isSmall ? 'flex-start' : 'flex-end'}>
                  <IconButton href="https://www.facebook.com/MainStreetAmerica/">
                    <FacebookIcon color="secondary" />
                  </IconButton>
                  <IconButton href="https://twitter.com/msa_ins">
                    <TwitterIcon color="secondary" />
                  </IconButton>
                  <IconButton href="https://www.linkedin.com/company/main-street-america-insurance">
                    <LinkedInIcon color="secondary" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </StyledFooterMain>
      <Divider />
      <StyledFooterFoot>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box mb={`${rem(32)}`}>
              <Typography variant="body1" color="textSecondary">
                {`Copyright © ${date} Main Street America Insurance. All rights reserved.`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent={isSmall ? 'flex-start' : 'flex-end'}>
              {/* <Box sx={{ width: isSmall ? `100%` : `${rem(364)}` }}> */}
              {footLinks.map((link, index) => (
                <Box
                  key={cuid()}
                  display="inline-block"
                  mr={index === footLinks.length - 1 ? 0 : 2}>
                  <Typography key={cuid()}>
                    <Link href={link.route} target="_blank" underline="hover" color="secondary">
                      {link.name}
                    </Link>
                  </Typography>
                </Box>
              ))}
            </Box>
            {/* </Box> */}
          </Grid>
        </Grid>
      </StyledFooterFoot>
    </StyledFooter>
  );
};

export default Footer;
