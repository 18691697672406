import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import _ from 'lodash';
import cuid from 'cuid';
import { useParams, useHistory } from 'react-router-dom';

import { ScreenSmith } from '../ScreenSmith';
import { CardTabSkeleton } from '../AOSkeleton';
import { AOCircularProgress } from '../AOCircularProgress';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { GET_BILLING_DETAILS } from '../../constants/api';
import {
  BillingInquiryDetailsPDFButton,
  BillingInquiryDetailsPaymentModal,
  BillingInquiryEftIndicatorModal,
} from './BillingInquiryDetails.components';
import {
  handlePrintPDFOnClick,
  activitySummaryListColumns,
  getDirectBilledPoliciesColumns,
  futureBillingsColumns,
  handleAOTextFieldOnChange,
  handleContinueOnClick,
  getPaymentAmount,
  handleCloseModal,
  handleGoBack,
} from './BillingInquiryDetails.api';
import { StyledBillingInquiryDetails } from './BillingInquiryDetails.styled';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import AppDataContext from '../../contexts/appData.context';

const LocalComponents = {
  BillingInquiryDetailsPDFButton,
  BillingInquiryDetailsPaymentModal,
  BillingInquiryEftIndicatorModal,
  AOCircularProgress,
};

const BillingInquiryDetails = () => {
  const [loading, setLoading] = useState(true);
  const [accountDetail, setAccountDetail] = useState();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [paymentAmountType, setPaymentAmountType] = useState('');
  const [paymentOtherAmount, setPaymentOtherAmount] = useState('');
  const [paymentNote, setPaymentNote] = useState('');
  const [paymentFeedback, setPaymentFeedback] = useState('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isMakePaymentRedirectErrorVisible, setIsMakePaymentRedirectErrorVisible] = useState(false);
  const [makePaymentRedirectError, setMakePaymentRedirectError] = useState(false);
  const [eftIndicatorModal, setEftIndicatorModal] = useState(false);

  const { accountNumber } = useParams();
  const history = useHistory();
  const { previousPageName, setClaimsInquiry } = useContext(AppDataContext);

  useEffect(async () => {
    try {
      const getAccountDetailUrl = `${GET_BILLING_DETAILS}/${accountNumber}`;
      const details = await axios.get(getAccountDetailUrl);
      setAccountDetail(details.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  const { UiDefinitionsData, loadingSd } = useScreenDefinition('BillingInquiryDetails');
  const buildRequestBodyForPaymentus = () => {
    const directBillAccountInfo = accountDetail.directBilledAccountInfo;
    const e1pPolicies = accountDetail.activitySummaryList.filter(
      (activitiySummary) => activitiySummary.policySourceSystem === 'G',
    );
    const farmRanchPolicies = accountDetail.activitySummaryList.filter(
      (activitiySummary) => activitiySummary.policySourceSystem === 'F',
    );
    let policyNumber = directBillAccountInfo.accountNumber;
    if (accountDetail?.isGuidewirePolicy) {
      policyNumber = e1pPolicies.length > 0 ? e1pPolicies[0].policyNumber : '';
    } else if (accountDetail?.isFarmRanchPolicy) {
      policyNumber = farmRanchPolicies.length > 0 ? farmRanchPolicies[0].policyNumber : '';
    } else {
      policyNumber = directBillAccountInfo.accountNumber;
    }

    const requestBody = {
      Area: 'Billing Payment',
      TypeOfRedirect: 'Agent Payments',
      DetailedContext: [
        {
          name: 'policyNumber',
          value: policyNumber,
        },
        {
          name: 'path',
          value: 'billing',
        },
        {
          name: 'AccountDetails',
          value: {
            accountNumber: directBillAccountInfo.accountNumber.trim(),
            agencyNumber:
              directBillAccountInfo.agentNumber.length === 9
                ? directBillAccountInfo.agentNumber
                : directBillAccountInfo.agentNumber + '001',
            billingZipCode: directBillAccountInfo.postalCode5.trim(),
            expectedAmount: directBillAccountInfo.minimumDue,
            producerCode: directBillAccountInfo.producerCode,
            totalPaymentAmount: directBillAccountInfo.minimumDue,
          },
        },
        {
          name: 'Payor',
          value: {
            FirstName: directBillAccountInfo.name1.trim(),
            LastName: directBillAccountInfo.lastName,
          },
        },
      ],
    };
    return requestBody;
  };

  const handleRadioButtonOnChange = (e, stateSetFunc) => {
    stateSetFunc(e.target.value);
    if (e.target.value === 'insuredportal') {
      handleMakePayment();
    }
  };

  const handleMakePayment = () => {
    if (accountDetail.directBilledAccountInfo.eftindicator === '1') {
      setEftIndicatorModal(true);
    } else {
      paymentRedirect();
    }
  };

  const closeEftIndicatorModal = () => {
    setEftIndicatorModal(false);
  };

  const paymentRedirect = () => {
    if (eftIndicatorModal) {
      closeEftIndicatorModal();
    }
    return redirectUrlFunction(
      buildRequestBodyForPaymentus(),
      'POST',
      null,
      setMakePaymentRedirectError,
      setIsMakePaymentRedirectErrorVisible,
    );
  };

  const productDetailState = {
    loading,
    ...accountDetail?.directBilledAccountInfo,
    directBilledPolicies:
      accountDetail?.directBilledPolicies?.map((el) => ({
        id: cuid(),
        ...el,
      })) ?? [],
    directBilledPoliciesColumns: getDirectBilledPoliciesColumns(setClaimsInquiry),
    ...accountDetail?.feeInfo,
    activitySummaryList:
      accountDetail?.activitySummaryList?.map((el) => ({
        id: cuid(),
        ...el,
      })) ?? [],
    activitySummaryListColumns,
    exportToCSVActivitySummaryFileName: `activity-summary-${accountNumber}`,
    futureBillings:
      accountDetail?.futureBillings?.map((el) => ({
        id: cuid(),
        ...el,
      })) ?? [],
    futureBillingsColumns,
    showNoFutureBillings: accountDetail?.futureBillings?.length < 1,
    showFutureBillings: accountDetail?.futureBillings?.length > 0,
    showPaymentAmounts: paymentType !== 'agent',
    showPaymentRadioButtons:
      !accountDetail?.isSbssPolicy &&
      !accountDetail?.isGuidewirePolicy &&
      !accountDetail?.isFarmRanchPolicy,
    showMakePaymentButton:
      accountDetail?.isSbssPolicy ||
      accountDetail?.isGuidewirePolicy ||
      accountDetail?.isFarmRanchPolicy,
    paymentType,
    paymentAmountType,
    paymentOtherAmount,
    paymentNote,
    paymentFeedback,
    showPaymentModal,
    loadingPdf,
    isDownloadingPdfErrorVisible,
    downloadingPdfError,
    isMakePaymentRedirectErrorVisible,
    makePaymentRedirectError,
    paymentAmount: getPaymentAmount(
      accountDetail?.directBilledAccountInfo?.minimumDue,
      accountDetail?.directBilledAccountInfo?.accountBalance,
      paymentOtherAmount,
      paymentAmountType,
    ),
    eftIndicatorModal,
    previousPageName: previousPageName ?? 'Billing Services',
  };

  const productDetailCallbackFunctions = {
    handlePrintPDFOnClick: () =>
      handlePrintPDFOnClick(
        accountNumber,
        setLoadingPdf,
        setDownloadingPdfError,
        setIsDownloadingPdfErrorVisible,
      ),
    handleMakePayment,
    paymentRedirect,
    closeEftIndicatorModal,
    handlePaymentTypeRadioOnChange: (e) => handleRadioButtonOnChange(e, setPaymentType),
    handlePayAmountRadioOnChange: (e) => handleRadioButtonOnChange(e, setPaymentAmountType),
    handlePaymentOtherAmountOnChange: (e) => handleAOTextFieldOnChange(e, setPaymentOtherAmount),
    handlePaymentTypeOnChange: (e) => handleAOTextFieldOnChange(e, setPaymentNote),
    handleContinueOnClick: () =>
      handleContinueOnClick(
        paymentAmountType,
        paymentNote,
        paymentOtherAmount,
        setPaymentFeedback,
        setShowPaymentModal,
      ),
    handleCloseModal: () => handleCloseModal(setShowPaymentModal),
    handleGoBack: () => handleGoBack(history, previousPageName),
  };

  return loadingSd ? (
    <CardTabSkeleton />
  ) : (
    <StyledBillingInquiryDetails>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={LocalComponents}
          functionMap={productDetailCallbackFunctions}
          stateMap={productDetailState}
        />
      )}
    </StyledBillingInquiryDetails>
  );
};

export default BillingInquiryDetails;
