import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Box, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';

import { GET_ALL_AGENTS } from '../../constants/api';
import { AOCircularProgress } from '../AOCircularProgress';
import { AOTextField } from '../AOTextField';
import { AOIconButton } from '../AOIconButton';
import { AgentLookupModal } from '../AgentLookupModal';
import { AOButton } from '../AOButton';

const AppetiteAgentValidationModalInternal = ({
  selectedCode,
  setSelectedCode,
  handleSetAgencyCode,
  handleCloseOnClick,
  open,
  agentCodes,
  setAgentCodes,
}) => {
  const [isAgentLookupModalOpen, setAgentLookupModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(async () => {
    try {
      const results = await axios.get(`${GET_ALL_AGENTS}/commercial-lines`);
      setAgentCodes(results.data.Results.map((agency) => agency.agencyCode ?? ''));
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  const handleValidateInternalUser = (selectedCode) => {
    if (agentCodes.includes(selectedCode)) {
      handleSetAgencyCode(selectedCode);
    } else {
      setErrorMessage('Not a valid agency code.');
    }
  };

  return loading ? (
    <AOCircularProgress visible={loading}>Fetching agent codes...</AOCircularProgress>
  ) : (
    <>
      <Modal
        open={open}
        onClose={handleCloseOnClick}
        aria-labelledby="appetite-agent-code-validation-modal-title"
        aria-describedby="appetite-agent-code-validation-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper elevation={3}>
          <Box p={3} maxWidth={500}>
            <Typography variant="h6">Enter an agency code</Typography>
            <Box mb={2} />
            <Typography color="textSecondary">
              Select the correct agency code to continue with this quote.
            </Typography>
            <Box mb={2} />
            <Box display="flex">
              <AOTextField
                id="appetite-agent-code"
                label={'Agency Code'}
                name="locationCode"
                onChange={(e) => {
                  setErrorMessage('');
                  setSelectedCode(e.target.value);
                }}
                invalidSearch={errorMessage}
                value={selectedCode}
              />
              <Box ml={0.25} mt={0.25}>
                <AOIconButton
                  id="appetite-agent-lookup-button"
                  onClick={() => setAgentLookupModalOpen(true)}
                />
              </Box>
            </Box>
            <AgentLookupModal
              open={isAgentLookupModalOpen}
              setAgentCode={(value) => {
                setErrorMessage('');
                setSelectedCode(value);
              }}
              lineOfBusiness="commercial-lines"
              setOpen={setAgentLookupModalOpen}
            />
            <Box mt={2} display="flex" justifyContent={'flex-end'}>
              <AOButton color="secondary" onClick={handleCloseOnClick}>
                Close
              </AOButton>
              <Box mr={1} />

              <AOButton
                id="appetite-use-this-code"
                color="secondary"
                onClick={() => handleValidateInternalUser(selectedCode)}
                disabled={!selectedCode}>
                Use This Code
              </AOButton>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

AppetiteAgentValidationModalInternal.propTypes = {
  open: PropTypes.bool,
  selectedCode: PropTypes.string,
  setSelectedCode: PropTypes.func,
  handleSetAgencyCode: PropTypes.func,
  handleCloseOnClick: PropTypes.func,
  agentCodes: PropTypes.array,
  setAgentCodes: PropTypes.func,
};

export default AppetiteAgentValidationModalInternal;
