import { rem } from 'polished';

import { Box, CircularProgress, styled } from '@material-ui/core';

const StyledAOButton = styled(Box)(({ theme, fullHeight, fullWidth }) => ({
  padding: theme.spacing(0),
  position: 'relative',
  display: 'inline-block',
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(0)}`,
  },
  height: fullHeight ? '100%' : '',
  width: fullWidth ? '100%' : '',
}));

const StyledButtonProgress = styled(CircularProgress)({
  color: 'primary',
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});

export { StyledAOButton, StyledButtonProgress };
