import { rem } from 'polished';

import { Typography, styled } from '@material-ui/core';

const StyledHeadline = styled(Typography)(({ theme }) => ({
  fontSize: `${rem(36)}`,
  fontFamily: `Montserrat`,
  fontWeight: `500`,
  marginBottom: `${rem(45)}`,
  color: theme.palette.text.primary,
}));

const StyledHeadlineWithSubtitle = styled(StyledHeadline)(() => ({
  fontSize: `${rem(28)}`,
  marginBottom: `${rem(4)}`,
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: `${rem(14)}`,
  marginBottom: `${rem(45)}`,
  color: theme.palette.text.secondary,
}));

export { StyledHeadline, StyledHeadlineWithSubtitle, StyledSubtitle };
