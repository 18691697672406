import { rem } from 'polished';
import { Box, styled } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AOButton from '../AOButton/AOButton';

const StyledProductDetail = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(16)}`,
  },
}));

const ProductDetailMapLegend = styled('span')(({ theme }) => ({
  background: theme.palette.primary.main,
  height: rem(15),
  width: rem(15),
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: rem(8),
}));

const StyledProductDetailText = styled(Typography)(({ theme }) => ({
  margin: '1rem 0',
  fontSize: rem(16),
  lineHeight: rem(24),
  letterSpacing: rem(0.15),
  color: theme.palette.text.secondary,
  whiteSpace: 'pre-line',
}));

const StyledProductDetailOverview = styled(Paper)(() => ({
  fontSize: `${rem(12)}`,
  padding: '1rem',
}));

const StyledProductDetailAccordionHeader = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: rem(20),
  lineHeight: rem(32),
  letterSpacing: rem(0.15),
}));

const StyledProductDetailAccordionTypography = styled(Typography)(() => ({
  fontSize: `${rem(12)}`,
}));

const StyledProductDetailAttachmentButton = styled(AOButton)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  lineHeight: rem(24),
  fontSize: rem(16),
  textTransform: 'inherit',
  '& .MuiButton-label': {
    color: theme.palette.secondary.main,
  },
}));

export {
  StyledProductDetail,
  ProductDetailMapLegend,
  StyledProductDetailText,
  StyledProductDetailOverview,
  StyledProductDetailAccordionHeader,
  StyledProductDetailAccordionTypography,
  StyledProductDetailAttachmentButton,
};
