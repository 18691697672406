import axios from 'axios';

import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';
import { handleClickLogout } from '../Header/Header.api';
import { LEGAL_AGREEMENT_ACCEPT, LEGAL_AGREEMENT_PDF_DOWNLOAD } from '../../constants/api';

const handleAgreeOnClick = (setAgreeButtonLoading, setError, hideLegalAgreementModal) => {
  setAgreeButtonLoading(true);
  setError('');

  axios
    .get(LEGAL_AGREEMENT_ACCEPT)
    .then((response) => {
      console.info(response);
      hideLegalAgreementModal();
    })
    .catch((error) => {
      console.error(error);
      setError(
        'There was a problem processing this request. We are sorry for the inconvenience. Please contact the Help Desk at 1-877-927-5672 or try again later.',
      );
    })
    .finally(() => {
      setAgreeButtonLoading(false);
    });
};

const handleDeclineOnClick = (setError, oktaAuth) => {
  setError('');
  handleClickLogout(oktaAuth);
};

const handleDownloadLegalAgreementOnClick = (
  setLoadingPdf,
  setDownloadingPdfError,
  setIsDownloadingPdfErrorVisible,
) => {
  handlePdfDownload(
    'GET',
    null,
    LEGAL_AGREEMENT_PDF_DOWNLOAD,
    setLoadingPdf,
    setDownloadingPdfError,
    setIsDownloadingPdfErrorVisible,
    'newWindow',
  );
};

export { handleAgreeOnClick, handleDeclineOnClick, handleDownloadLegalAgreementOnClick };
