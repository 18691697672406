import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useOktaAuth } from '@okta/okta-react';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { ScreenSmith } from '../ScreenSmith';
import LegalAgreementSkeleton from './LegalAgreementSkeleton';
import {
  handleAgreeOnClick,
  handleDeclineOnClick,
  handleDownloadLegalAgreementOnClick,
} from './LegalAgreement.api';
import { StyledLegalAgreementContainer } from './LegalAgreement.styled';
import { AOButton } from '../AOButton';
import { AOLinkButton } from '../AOLinkButton';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import { AOAlert } from '../AOAlert';

const LegalAgreement = ({ hideLegalAgreementModal }) => {
  const { oktaAuth } = useOktaAuth();
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('LegalAgreement');

  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const [agreeButtonLoading, setAgreeButtonLoading] = useState(false);
  const [error, setError] = useState('');

  return (
    <Modal
      open
      aria-labelledby="legal-agreement-modal-title"
      aria-describedby="legal-agreement-modal-description">
      <StyledLegalAgreementContainer>
        <>
          {loadingSd ? (
            <LegalAgreementSkeleton />
          ) : (
            !_.isNil(UiDefinitionsData) && <ScreenSmith definition={UiDefinitionsData} />
          )}
        </>
        <AOLinkButton
          onClick={() => handleDownloadLegalAgreementOnClick(setDownloadingPdf, setError, setError)}
          loading={downloadingPdf}>
          Click Here to Download this Legal Agreement in Adobe .PDF format
        </AOLinkButton>
        <Box mt={2} mb={3}>
          <Divider />
        </Box>
        {error && <AOAlert severity="error">{error}</AOAlert>}
        <Box display="flex" justifyContent="flex-end">
          <AOButton
            onClick={() => handleDeclineOnClick(setError, oktaAuth)}
            color="primary"
            variant="outlined">
            I Decline
          </AOButton>
          <Box mr={2} />
          <AOButton
            loading={agreeButtonLoading}
            onClick={() =>
              handleAgreeOnClick(setAgreeButtonLoading, setError, hideLegalAgreementModal)
            }
            color="primary"
            variant="contained">
            I Agree
          </AOButton>
        </Box>
      </StyledLegalAgreementContainer>
    </Modal>
  );
};

LegalAgreement.propTypes = {
  hideLegalAgreementModal: PropTypes.func,
};

export default LegalAgreement;
