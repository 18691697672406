import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const AOIconButton = ({ icon, ...otherProps }) => {
  const iconType = icon;
  const IconComponents = {
    SearchIcon,
  };
  return React.createElement(
    IconButton,
    {
      ['aria-label']: `${iconType}`,
      ...otherProps,
    },
    React.createElement(IconComponents[iconType], null, null),
  );
};

AOIconButton.defaultProps = {
  icon: 'SearchIcon',
};

AOIconButton.propTypes = {
  icon: PropTypes.string,
};

export default AOIconButton;
