import React, { useContext } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import { CardTabSkeleton } from '../AOSkeleton';

import AgencyToolkitReportsStatements from './AgencyToolkitReportsStatements';
import AgencyToolkitSupplyOrdering from './AgencyToolkitSupplyOrdering';
import AgencyToolkitMarketingMaterials from './AgencyToolkitMarketingMaterials';
import AgencyToolkitTrainingMaterials from './AgencyToolkitTrainingMaterials';
import AgencyToolkitAgencyAutomation from './AgencyToolkitAgencyAutomation';
import AgencyToolkitNewAgents from './AgencyToolkitNewAgents';

import { StyledAgencyToolkit } from './AgencyToolkit.styled';

import useScreenDefinition from '../../hooks/useScreenDefinition';

import { handleRoute } from '../CommonUtilityServices/HandleRouteService';
import { handleTabChange } from '../CommonUtilityServices/EventHandlerService';
import AppConfigContext from '../../contexts/appConfig.context';

const PanelComponents = {
  AgencyToolkitSupplyOrdering,
  AgencyToolkitTrainingMaterials,
  AgencyToolkitMarketingMaterials,
  AgencyToolkitReportsStatements,
  AgencyToolkitAgencyAutomation,
  AgencyToolkitNewAgents,
};

const AgencyToolkit = () => {
  // Screen defs
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`AgencyToolkitHome`);
  const { legacyUrl } = useContext(AppConfigContext);

  const [tabValue, setTabValue] = React.useState(0);

  const plState = {
    tabValue,
  };

  const callBackFunctions = {
    handleRoute,
    handleTabChange: (e, newValue) => handleTabChange(e, newValue, setTabValue),
    handleRouteToLegacyOnClick: (event) => {
      const route = event.currentTarget.getAttribute('data-route');
      window.open(`${legacyUrl}${route}`, '_blank');
    },
  };

  if (loadingSd) return <CardTabSkeleton />;

  return (
    <StyledAgencyToolkit>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={PanelComponents}
          functionMap={callBackFunctions}
          stateMap={plState}
        />
      )}
    </StyledAgencyToolkit>
  );
};

export default AgencyToolkit;
