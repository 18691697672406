import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core';

import theme from './theme';

const MSAThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
MSAThemeProvider.defaultProps = {
  children: PropTypes.isNotNull, // you can amend this accordingly
};
MSAThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default MSAThemeProvider;
