import axios from 'axios';
import {
  ALL_STATES_FOR_NEWS,
  ADD_TEXT_NEWS,
} from '../../../constants/api';

const getStates = async () => {
  const { data } = await axios.get(`${ALL_STATES_FOR_NEWS}`);
  return data;
};

const submitTextNews = async (_payload) => {
  let post;
  try {
    post = await axios.post(ADD_TEXT_NEWS, { ..._payload });
  } catch (error) {
    post = error;
  }
  return post;
};


export { getStates, submitTextNews };
