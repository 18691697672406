import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const AgentLogout = ({ ...props }) => {
  return (
    <Box style={{ height: `64px` }} display="flex" flexDirection="column" justifyContent="center">
      <IconButton {...props}>
        <ExitToAppIcon />
      </IconButton>
    </Box>
  );
};

export default AgentLogout;
