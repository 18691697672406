import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// AO Components
import { RowCol } from '../../../ScreenSmith';
import PaymentHistoryFilter from './PaymentHistoryFilter';
import PaymentHistoryDataGrid from './PaymentHistoryDataGrid/PaymentHistoryDataGrid';

// AO Common Hooks & Event handlers
import {
  autocompleteInputValuesInit,
  formDataInit,
  getPaymentHistoryGridRowsFilter,
  getPaymentHistoryMenuItems,
  menuItemsInit,
} from './PaymentHistory.api';
import {
  paymentHistoryGridColDef,
  getPaymentHistoryGridRows,
} from './PaymentHistoryDataGrid/PaymentHistoryDataGrid.api';
import { handleClickBackToPaymentManagement } from '../../PaymentManagement/BillingServicesPaymentManagement.api';
import { useDebounce } from '../../../../hooks/useDebounce';
import {
  handleAOAutocompleteOnBlur,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleFromDateOnChange,
  handleOnChange as handleOnChangeService,
  handleToDateOnChange,
} from '../../../CommonUtilityServices/EventHandlerService';
// import { PaymentManagementHistoryTransactionDetailModal } from './PaymentManagementHistoryTransactionDetailModal';

const PaymentHistory = ({ resetFlow }) => {
  const [formData, setFormData] = useState(formDataInit);
  const [menuItems, setMenuItems] = useState(menuItemsInit);
  const [autocompleteInputValues, setAutocompleteInputValues] = useState(
    autocompleteInputValuesInit,
  );
  const [paymentHistoryGridRows, setPaymentHistoryGridRows] = useState([]);
  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);

  /* Callback fired when dropdown value changes */
  const handleOnChange = (e) => {
    handleOnChangeService(e, formData, setFormData);
  };

  /* Callback fired when the Autocomplete value changes. */
  const handleOnChangeAutocomplete = (e, _value, _name) =>
    handleAOAutocompleteOnChange(_name, e, _value, formData, setFormData);

  /* Callback fired when the input value changes. */
  const handleOnInputChangeAutocomplete = (e, _value, _name) =>
    handleAOAutocompleteOnInputChange(
      _name,
      e,
      _value,
      autocompleteInputValues,
      setAutocompleteInputValues,
      debouncedState,
      setDebouncedState,
    );
  /* Callback fired when the value changes due to user leaving input field */
  const handleOnBlurAutocomplete = (_name) =>
    handleAOAutocompleteOnBlur(_name, formData, setFormData, autocompleteInputValues);

  /* Callback fired when the From Date value changes. */
  const handleOnChangeFromDate = (date) => {
    handleFromDateOnChange(date, formData, setFormData, 'paymentHistoryFromDate');
  };

  /* Callback fired when the To Date value changes. */
  const handleOnChangeToDate = (date) => {
    handleToDateOnChange(date, formData, setFormData, 'paymentHistoryToDate');
  };

  const handleOnClickFilterResults = async () => {
    const paymentHistory = await getPaymentHistoryGridRowsFilter(formData);
    setPaymentHistoryGridRows(paymentHistory);
  };

  /* Reset filters by setting them to empty value. */
  const handleOnClickResetFilters = () => {
    setFormData(formDataInit);
    setAutocompleteInputValues(autocompleteInputValuesInit);
  };

  /* Fetch menu items */
  useEffect(async () => {
    const paymentHistoryMenuItems = await getPaymentHistoryMenuItems();
    setMenuItems(paymentHistoryMenuItems);
  }, []);

  /* Fetch Grid Row table stuct */
  useEffect(async () => {
    const paymentHistory = await getPaymentHistoryGridRows();
    setPaymentHistoryGridRows(paymentHistory);
  }, []);

  return (
    <RowCol>
      <RowCol mb={2}>
        <Button
          startIcon={<ArrowBackIcon />}
          color="primary"
          size="small"
          onClick={() => handleClickBackToPaymentManagement(resetFlow)}>
          Back to Payment Management
        </Button>
      </RowCol>
      <RowCol mb={2}>
        <Typography variant="h5">Payment History</Typography>
      </RowCol>
      <RowCol mb={4}>
        <PaymentHistoryFilter
          autocompleteInputValues={autocompleteInputValues}
          formData={formData}
          handleOnBlurAutocomplete={handleOnBlurAutocomplete}
          handleOnChange={handleOnChange}
          handleOnChangeAutocomplete={handleOnChangeAutocomplete}
          handleOnChangeFromDate={handleOnChangeFromDate}
          handleOnChangeToDate={handleOnChangeToDate}
          handleOnClickFilterResults={handleOnClickFilterResults}
          handleOnClickResetFilters={handleOnClickResetFilters}
          handleOnInputChangeAutocomplete={handleOnInputChangeAutocomplete}
          menuItems={menuItems}
        />
      </RowCol>
      <RowCol mb={4}>
        <PaymentHistoryDataGrid columns={paymentHistoryGridColDef} rows={paymentHistoryGridRows} />
      </RowCol>
      <RowCol>
        <Typography>First 20 records returned based on filtering</Typography>
        <Typography>Click on any transaction to display more detail.</Typography>
        <Typography>
          For Agency and Account, type the first few characters to get a filtered list.
        </Typography>
      </RowCol>
    </RowCol>
  );
};

PaymentHistory.propTypes = {
  resetFlow: PropTypes.func,
};

export default PaymentHistory;
